require('bc-image-loader');

const $ = require('jquery');
global.jQuery = $;

// Initialize all global stuff with data from back-end
const { initGlobals } = require('../../common/js/commonSetup');
initGlobals();
require('slick-carousel');

require('../../../../shared/lib/handlebarsHelpersTime')();
const { getSortMethod } = require('../../common/js/sorting');
require('../../common/js/videofinisher');
const { setupPlayers, teardownPlayers } = require('../../common/js/perform');
const utils = require('../../common/js/utils');
const _ = require('underscore');

const breakpointMobile = 432;
const breakpointTablet = 760;
const breakpointDesktop = 1224;

//---------------------------------------------------------------------------------
// Main
class Main {
  constructor () {
    this.mq = 320;
    this.mobileImagesLoaded = false;
    this.desktopImagesLoaded = false;
    this.overflowsCache = null;
    this.adjustOverflowsTimeout = 0;
  }

  init () {
    this.cacheOverflows();

    $('.bcg-home-carousel-container-mobile .home-carousel-prev.slick-arrow').attr('display: none;');

    //Apply visible focus to nav links for non-webkit browsers.
    $('.bcg-main-header-title, .main-nav-category a').focusin(function () {
      if (navigator.userAgent.indexOf('Chrome') === -1) {
        this.parentElement.setAttribute('style', 'border: 2px solid #70BFF8;');
        this.setAttribute('style', 'outline: none;');
      }
      this.setAttribute('style', 'outline-offset: -3px; outline-color: #0366c9;');
    });

    //Remove applied focus from nav links
    $('.bcg-main-header-title, .main-nav-category a').focusout(function () {
      if (navigator.userAgent.indexOf('Chrome') === -1) {
        this.parentElement.setAttribute('style', 'outline: none;');
      }
    });

    //Apply visible focus to load more, carousel paging buttons, index page featured video & title, menu button and footer links
    $('.main-header-search-icon, .home-carousel-prev, .home-carousel-next, .video-grid-item-more, .home-carousel-title a, .bc-player-placeholder, .footer-lists a, #menu-button').focusin(e => {
      this.focusThis(e.currentTarget);
    });

    //Remove applied focus from load more, carousel paging buttons, index page featured video & title, menu button and footer links
    $('.main-header-search-icon, .home-carousel-prev, .home-carousel-next, .video-grid-item-more, .home-carousel-title a, .bc-player-placeholder, .footer-lists a, #menu-button').focusout(e => {
      this.focusThis(e.currentTarget);
    });

    //adds visible focus to social icon boxes and download link box, otherwise the outline will be solely around the icon / link text
    $('.share-list a, .cta-content-inner a, #video-download a, .main-footer-social a').focusin(e => {
      this.setAttribute('style', 'outline: none;');
      if (navigator.userAgent.indexOf('Chrome') > -1) {
        this.parentElement.setAttribute('style', 'outline: 5px auto #0366c9;');
        if ($(e.currentTarget).hasClass('fa')) {
          $(e.currentTarget.parentElement).css('background-color', '#2574A9');
        }
      }
      this.focusThis(e.currentTarget.parentElement);
    });

    $('.share-list a, .cta-content-inner a, #video-download a, .main-footer-social a').focusout(e => {
      this.blurThis(e.currentTarget.parentElement);
      e.currentTarget.parentElement.setAttribute('style', 'outline: none;');
    });

    //on focus, highlight the sort by menu (Chrome only)
    $('.chosen-search input').focusin(function () {
      if (navigator.userAgent.indexOf('Chrome') > -1) {
        this.parentElement.parentElement.parentElement.setAttribute('style', 'outline: 3px auto -webkit-focus-ring-color;');
      }
    });

    $('.chosen-search input').focusout(function () {
      this.parentElement.parentElement.parentElement.setAttribute('style', 'outline: none;');
    });

    //on focus, switch outline type depending on browser
    $('.video-grid-item a, .share-list a').focusin(function () {
      if (navigator.userAgent.indexOf('Chrome') === -1) {
        $(this.parentElement).addClass('bcg-non-webkit-focus');
        this.setAttribute('style', 'outline: none;');
      }
    });

    //on leave focus, remove outline
    $('.video-grid-item a, .share-list a').focusout(function () {
      if (navigator.userAgent.indexOf('Chrome') === -1) {
        $(this.parentElement).removeClass('bcg-non-webkit-focus');
      }
    });

    this.changeFocusOrder();
    this.makeSortByAccessible();
  }

  //For adding accessibility to sort by menu
  //currently only for input
  makeSortByAccessible () {
    if (window.site.sorting.enabled) {
      $('.sortVideos-wrapper .chosen-drop .chosen-search input').attr({
        'type': 'button',
        'aria-label': 'sort by menu',
      });
    }
  }

  //Helper for focusing, focuses on the provided element
  focusThis (element) {
    if (navigator.userAgent.indexOf('Chrome') === -1) {
      $(element).addClass('bcg-non-webkit-focus');
    } else {
      $(element).css('outline-color', '#0366c9');
    }
  }

  //Helper for focusing, 'blurs' (removes visible focus) from the provided element
  blurThis (element) {
    if (navigator.userAgent.indexOf('Chrome') === -1) {
      $(element).removeClass('bcg-non-webkit-focus');
    }
  }

  cacheOverflows () {
    this.overflowsCache = [];

    let cacheId = 0;
    $('.video-grid-item').each((index, element) => {
      const $gridItem = $(element);
      $gridItem.attr('data-bc-cache-id', cacheId);
      this.overflowsCache[cacheId] = $gridItem.find('.video-grid-description').html();
      cacheId++;
    });
  }

  setMq () {
    const mq = $('body').css('z-index');
    this.mq = parseInt(mq, 10);
  }

  //from: https://stackoverflow.com/questions/901115/how-can-i-get-query-string-values-in-javascript
  getParameterByName (name, url) {
    if (!url) {
      url = window.location.href;
    }
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(url);
    if (!results) {
      return null;
    }
    if (!results[2]) {
      return '';
    }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  changeFocusOrder () {
    if (window.site.dynamicCustom.burgerMenu && !$('#bcg-main-header').hasClass('main-nav-open')) {
      //if the menu is present, make it focusable before home link
      $('#menu-button').attr('tabindex', '1');
      $('.main-header-logo .bcg-main-header-title').attr('tabindex', '2');
    }
  }

  styledHeight ($element) {
    let styledHeight = $element.height();
    styledHeight += parseInt(($element.css('margin-top') || '0').replace('px', ''), 10);
    styledHeight += parseInt(($element.css('margin-bottom') || '0').replace('px', ''), 10);

    return styledHeight;
  }

  adjustSiteContainerHeight () {
    const dynamicCustom = window.site.dynamicCustom;
    const screenHeight = $(window).height();
    const screenWidth = $(window).width();

    const $siteContainer = $('.site-container');
    const $mainContent = $('.main-content');

    const $header = $('header.main-header');
    const $customHeader = $('.customHeaderContainer');
    const $footer = $('footer.main-footer');
    const $customFooter = $('.customFooterContainer');
    const $ctaContainer = $('.bcg-cta-header-container');

    let headerHeight = 0;
    let customHeaderHeight = 0;
    let footerHeight = 0;
    let customFooterHeight = 0;
    let ctaContainerHeight = 0;

    // Get element heights if the are above/below $mainContent
    if (screenWidth > breakpointDesktop && !(dynamicCustom && dynamicCustom.burgerMenu)) {
      if (!(dynamicCustom && dynamicCustom.sidebarFooter)) {
        footerHeight = $footer.outerHeight(true);
      }
    } else {
      headerHeight = $header.outerHeight(true);
      footerHeight = $footer.outerHeight(true);
    }

    if ($ctaContainer) {
      ctaContainerHeight = $ctaContainer.outerHeight(true);
    }

    if ($customHeader) {
      customHeaderHeight = $customHeader.outerHeight(true);
    }

    if ($customFooter) {
      customFooterHeight = $customFooter.outerHeight(true);
    }

    if (screenHeight > $siteContainer.height()) {
      $siteContainer.css('min-height', 'calc(100vh - ' + (customHeaderHeight + customFooterHeight + footerHeight) + 'px)');
      $mainContent.css('min-height', 'calc(100vh - ' + (headerHeight + footerHeight + customHeaderHeight + customFooterHeight + ctaContainerHeight) + 'px)');
    }
  }

  onWindowResize () {
    this.setMq();
    this.adjustSiteContainerHeight();

    const width = $(window).width();

    if (width <= breakpointTablet && !this.mobileImagesLoaded) {
      this.mobileImagesLoaded = true;
      window.bcGallery.imageLoader.loadImages();
    } else if (width > breakpointTablet && !this.desktopImagesLoaded) {
      this.desktopImagesLoaded = true;
      window.bcGallery.imageLoader.loadImages();

    }
  }
}

//-----------------------------------------------------------------------------------------------------------------------
// Header
class Header {
  constructor () {
    this.adjustNavHeightTimeout = 0;
  }
  init () {
    // Exit menu when escape key is pressed
    $('#menu-button, .main-nav-category').on('keydown', e => {
      if (e.keyCode === 27) { //escape key
        if ($('#menu-button').attr('aria-expanded') === 'true') {
          this.setMenu('close');
        }
      }
    });

    // Open/Close the menu (mobile only)
    $('.bcg-main-nav-toggle-container, .bcg-main-nav-close').on('click', () => {
      if ($('#menu-button').attr('aria-expanded') !== 'true') {
        this.setMenu('open');
      } else if ($('#menu-button').attr('aria-expanded') !== 'false') {
        this.setMenu('close');
      }

      this.adjustScrolling();
      this.adjustSearchWidth();
      this.adjustMenu();
    });

    // Main nav dropdowns
    $('nav.main-nav ul li .trigger-dropdown').click(toggleSubMenu);

    function toggleSubMenu () {
      const $this = $(this);

      const $parent = $this.parent();
      const closed = !$parent.hasClass('active-dropdown');

      $parent.toggleClass('active-dropdown');

      const $subList = $this.siblings('ul');
      $subList.toggleClass('show-dropdown hide-dropdown');

      const $subListFocusableElements = $subList.find('.main-nav-subcategory > .focusable');
      $subListFocusableElements.attr('tabindex', closed ? '0' : '-1');
    }

    updateTabIndexesForSubMenus();

    function updateTabIndexesForSubMenus () {
      const activeDropdownsFocusableElements = $('.active-dropdown > .show-dropdown > .main-nav-subcategory > .focusable');
      activeDropdownsFocusableElements.attr('tabindex', '0');
    }

    $(document).on('mousedown', e => {
      if ($(e.target).closest('.main-nav-category-header').length === 0 &&
          $(e.target).closest('.main-nav-subcategories').length === 0) {
        $('.main-nav-category').removeClass('selected');
      }
    });

    function closeSearch () {
      if ($(window).width() < breakpointMobile) {
        $('.main-header-search-container').fadeOut(function () {
          $('.main-header-search-container').css('display', '');
        });
      }
    }

    // Open search bar (mobile only)
    $('.main-header-search-open').on('click', function () {
      $('.main-header-search-container').fadeIn();
      $('.main-header-search-container :input').focus();
      $('.main-header').removeClass('main-nav-open');
      $('html').css('overflow', 'hidden');
      window.scrollTo(0, 0);
    });

    // Handle search input focus
    $('.main-header-search-input').focusin(function () {
      $('.main-header-search-container').addClass('bcg-main-header-focus');
    });

    $('.main-header-search-input').focusout(function () {
      $('.main-header-search-container').removeClass('bcg-main-header-focus');
    });

    const $lis = document.getElementById('main-nav-cat').getElementsByTagName('li');

    //closes out of menu if tab is pressed when last nav has focus
    $($lis[$lis.length - 1]).on('keydown', e => {
      if ($('#bcg-main-header').hasClass('bcg-burger-menu')) {
        //ensures that shift + tab will not trigger
        if (!e.shiftKey && e.keyCode === 9) {
          this.setMenu('close');
        }
      }
    });

    //closes out of menu if shift +tab is pressed at the top
    $($lis[0]).on('keydown', e => {
      //ensures that only shift + tab key will trigger
      if (e.shiftKey && e.keyCode === 9) {
        this.setMenu('close');
      }
    });

    // Close search bar (mobile only)
    $('.main-header-search-close').on('click', () => {
      closeSearch();
      $('html').css('overflow', 'auto');
      this.setMenu('close');
    });

    $('.main-header-search-container').on('click', () => {
      closeSearch();
    })
      .children()
      .click(e => {
        e.stopPropagation();
      });

    $('.main-header-search-input').on('input', function () {
      if ($('.main-header-search-input').val() === '') {
        $('.main-header-search-container').removeClass('main-header-search-container-has-text');
      } else {
        $('.main-header-search-container').addClass('main-header-search-container-has-text');
      }
    });

    // handle click on search icon
    $('.main-header-search-icon').on('click', e => {
      //only execute search if the correct search icon is clicked
      if ((!$(e.target).hasClass('main-header-search-open')) && (!$(e.target).hasClass('expand-search-button'))) {
        this.executeSearch();
      }
    });

    //handle enter on search icon
    $('.main-header-search-icon').on('keydown', e => {
      if (e.keyCode === 13 || e.keyCode === 32) {// enter or space
        e.preventDefault();
        this.executeSearch();
      }
    });

    // 'enter' for search
    $('.main-header-search-input').on('keyup', e => {
      if (e.keyCode === 13) {// enter
        if (e.originalEvent.isComposing) {  // don't execute search if keyboard is composing input
          return;
        }
        this.executeSearch();
      }
    });

    // Dismiss nav when link is clicked
    $('.main-nav a').on('click', () => {
      this.setMenu('close');
    });
  }

  executeSearch () {
    const searchTerm = $('.main-header-search-input').val();
    if (!searchTerm) {
      return;
    }
    document.location.href = utils.urlWithDevice(window.baseUrl + '/search?q=' + encodeURIComponent(searchTerm));
  }

  adjustScrolling () {
    if ($('.main-header').hasClass('main-nav-open')) {
      window.scrollTo(0, 0);
      $('body').css({
        overflow: 'hidden',
        position: 'fixed',
      });
    } else {
      $('body').css({
        overflow: 'auto',
        position: 'relative',
      });
    }
  }

  //Toggles the main nav and any relevant aria attributes
  setMenu (state) {
    if (window.site.dynamicCustom.burgerMenu || $(window).width() <= breakpointDesktop) {
      if (state === 'open') {
        $('.main-header-logo .bcg-main-header-title').attr('tabindex', '-1');
        $('#menu-button').attr('aria-expanded' , 'true');
        $('.bcg-main-nav-close-tablet').css('display', 'inline');
        $('#menu-button').css('display', 'none');
        $('#main-nav').attr('aria-hidden' , 'false');
        $('#main-nav').css('display', 'block');
        $('.main-header').addClass('main-nav-open');
        $('#main-nav').blur();
        $('.main-header-search-input').attr('tabindex', '-1');
        $('.main-header-search-icon').attr('tabindex', '-1');
      } else {
        this.closeBurgerMenu();
        this.adjustScrolling();
      }
    }
  }

  closeBurgerMenu () {
    $('.main-header-logo .bcg-main-header-title').attr('tabindex', '2');
    $('#menu-button').attr('aria-expanded' , 'false');
    $('.bcg-main-nav-close-tablet').css('display', 'none');
    $('#menu-button').css('display', '');
    $('#main-nav').attr('aria-hidden' , 'true');
    $('#main-nav').css('display', 'none');
    $('.main-header').removeClass('main-nav-open');
    $('#menu-button').focus();
    $('.main-header-search-input').attr('tabindex', '0');
    $('.main-header-search-icon').attr('tabindex', '0');
  }

  adjustSearchWidth () {
    const $search = $('.main-header-search');
    const $input = $search.find('input');
    const width = $(window).width() - 70;// 70px = toggle width

    if ($search.hasClass('open')) {
      $search.width(width);
      $input.width(width - 90);// 90px = toggle + 20 padding
    } else {
      $search.width('');
      $input.width('');
    }

    if ($(window).width() >= breakpointMobile) {
      $('.main-header-search-container').stop()
        .css('display', '');
    }
  }

  adjustMenu () {
    const width = $(window).width();
    const dynamicCustom = window.site.dynamicCustom;
    // if sidebar menu
    if (!(dynamicCustom && dynamicCustom.burgerMenu)) {
      if (width > breakpointDesktop) {
        $('.main-header').addClass('bcg-sidebar-menu');
        $('.main-header').removeClass('bcg-burger-menu');
        $('#bcg-main-header-cta').insertAfter('#main-header-logo-container');
        $('.site-container').addClass('bcg-has-sidebar-menu');
      } else {
        $('.main-header').addClass('bcg-burger-menu');
        $('#bcg-main-header-cta').insertAfter('#bcg-main-header');
        $('.main-header').removeClass('bcg-sidebar-menu');
        $('.site-container').removeClass('bcg-has-sidebar-menu');
        $('.main-footer').removeClass('main-footer-sidebar');
      }
    } else {
      $('#bcg-main-header-cta').insertAfter('#bcg-main-header'); //if it is a burger menu, the cta must come after the main header
    }
    $('.main-header').removeClass('bcg-main-header-hide');
  }

  onWindowResize () {
    this.adjustSearchWidth();
    this.adjustMenu();
    if ( !window.site.dynamicCustom.burgerMenu && $(window).width() > breakpointDesktop && $('.main-header').hasClass('main-nav-open')) {
      this.closeBurgerMenu();
      this.adjustScrolling();
      $('html, body, .main-content').css({
        overflow: 'auto',
        position: 'relative',
      });
    }
  }
}

const VIDEO_ASPECT_RATIO = 16 / 9;
const VISIBLE_THUMBNAIL_COUNT = 5;

//---------------------------------------------------------------------------------
// Carousel
class Carousel {
  constructor () {
    this.currentVideo = null;
    this.currentVideoSlideIndex = -1;
    this.previousSlideIndex = 0;
    this.templates = [];
    this.resizeTimeout = 0;

    //Since we don't currently have stage 2 / class properties in our babel config, we need to bind the event
    //handler functions
    this.handleThumbnailClick = this.handleThumbnailClick.bind(this);
  }

  init () {
    $('.home-carousel').on('afterChange', (event, slick, index) => {
      this.onAfterSlideChange(index);
      if (main.autoplayMobile) {
        setTimeout(function () {
          if (main.autoplayMobile) {
            main.autoplayMobile();
          }
          main.autoplayMobile = null; //clear this so the next transition does not autoplay automatically
        }, 150);
      }
    });

    $('.home-carousel').slick({
      dots: false,
      infinite: true,
      draggable: true,
      accessibility: false,
      swipe: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipeToSlide: true,
      speed: 300,
      centerMode: true,
      fade: false,
      centerPadding: '30px',
      prevArrow: '<button type="button" class="home-carousel-prev"></button>',
      nextArrow: '<button type="button" class="home-carousel-next"></button>',
      responsive: [
        {
          breakpoint: 776,
          settings: {
            arrows: false,
          },
        },
      ],
    });

    $('.bcg-thumbnail-carousel').on('init', (event, slick) => {
      this.slideCount = slick.slideCount;
      this.currentSlide = 0;
      window.bcGallery.imageLoader.loadImages();
    });

    $('.bcg-thumbnail-carousel').slick({
      dots: false,
      accessibility: false,
      infinite: true,
      swipe: true,
      speed: 500,
      slidesToShow: VISIBLE_THUMBNAIL_COUNT,
      slidesToScroll: 1,
      prevArrow: '<button type="button" class="home-carousel-prev" aria-label="' + window.translations.scroll_left + '"></button>',
      nextArrow: '<button type="button" class="home-carousel-next" aria-label="' + window.translations.scroll_right + '"></button>',
      responsive: [
        {
          breakpoint: 776,
          settings: {
            arrows: false,
          },
        },
      ],
    });

    this.bindEventHandlers();

    setTimeout(() => {
      this.setActiveSlide(0);
    }, 100);

    //when video is focused, if the prev and next buttons are not present, display them
    $('.bc-player-placeholder').focusin(() => {
      if (window.site.dynamicCustom.hideCarouselThumbnails) {
        if (window.site.dynamicCustom.hideCarouselChevrons) {
          this.displayChevrons();
        }
      }
    });

    $('.home-carousel-video .home-carousel-prev, .home-carousel-video .home-carousel-next').on('keydown', e => {
      if (window.site.dynamicCustom.hideCarouselThumbnails) {
        if (window.site.dynamicCustom.hideCarouselChevrons) {
          //tab key
          if (e.keyCode === 9) {
            if ($(e.currentTarget).hasClass('home-carousel-next')) {
              this.hideChevrons();
            }
          }
          //shift + tab
          if (e.shiftKey && e.keyCode === 9) {
            if ($(e.currentTarget).hasClass('home-carousel-prev')) {
              this.hideChevrons();
            }
          }
        }
      }
    });

    //when thumbnails are focused, if the prev and next buttons are not present, display them
    $('.bcg-thumbnail-carousel-slide').focusin(() => {
      if (window.site.dynamicCustom.hideCarouselChevrons) {
        this.displayChevrons();
      }
    });

    $('.home-carousel-next.slick-arrow, .home-carousel-prev.slick-arrow').focusout(() => {
      //only hide when hover only option is enabled
      if (window.site.dynamicCustom.hideCarouselChevrons) {
        this.hideChevrons();
      }
    });

    //adjust tab indecies on page load
    this.adjustTabindex();
  }

  displayChevrons () {
    $('.content-editable.bcg-home-carousel-container').removeClass('bcg-hide-carousel-chevrons');
    $('.home-carousel-next').attr('style', 'display: block!important;');
    $('.home-carousel-prev').attr('style', 'display: block!important;');
  }

  hideChevrons () {
    $('.home-carousel-next').attr('style', 'display: none;');
    $('.home-carousel-prev').attr('style', 'display: none;');
    $('.content-editable.bcg-home-carousel-container').addClass('bcg-hide-carousel-chevrons');
  }

  bindEventHandlers () {

    $('.bc-player-placeholder').on('keydown', (e) => {
      //begin playback of video
      //32 is space key, 13 is enter
      if (e.keyCode === 32 || e.keyCode === 13) {
        e.preventDefault();
        const $slide = $(e.currentTarget).closest('.home-carousel-video');
        if ($slide.attr('data-video-id')) {
          this.currentVideoSlideIndex = $slide.attr('data-slide-index');
          this.loadVideo($slide.attr('data-video-id'), '.home-carousel-video');
        }

        this.adjustTabindex();
      }
    });

    // click on carousel item

    // Desktop only
    $('.bcg-home-carousel-container').on('click', '.bc-player-placeholder', e => {
      e.preventDefault();
      const $slide = $(e.currentTarget).closest('.home-carousel-video');
      if ($slide.attr('data-video-id')) {
        this.currentVideoSlideIndex = $slide.attr('data-slide-index');
        this.loadVideo($slide.attr('data-video-id'), '.home-carousel-video');
      }

      this.adjustTabindex();
    });

    // Mobile/Tablet only
    $('.bcg-home-carousel-container-mobile').on('click', '.bc-player-placeholder', e => {
      e.preventDefault();
      const $slide = $(e.currentTarget).closest('.home-carousel-slide');
      if ($slide.attr('data-video-id')) {
        this.currentVideoSlideIndex = $slide.attr('data-slide-index');

        //Go to the new slide in the carousel
        const slideIndex = $slide.attr('data-slide-index') - 1;
        if (slideIndex !== $('.home-carousel').slick('slickCurrentSlide')) {
          this.previousSlideIndex = slideIndex;
          this.deleteVideos();
          //Use data-slick-index here to animate it the correct way
          $('.home-carousel').slick('slickGoTo', $slide.closest('.slick-slide').attr('data-slick-index'));
        }

        //for mobile carousel, we need to pass the actual slide object because the slide indicies are duplicated by slick carousel causing playback issues for the last 2 videos.
        //We need to fetch the current slide since it might have changed due to the above code
        this.loadVideo($slide.attr('data-video-id'), '.home-carousel-slide', $('.home-carousel .slick-current'));
      }
    });

    $('.home-carousel-next').on('click', e => {
      if (!$(e.target).hasClass('inactive')) {
        $(e.target).addClass('inactive');

        const currentSlide = this.currentSlide;
        const slideCount = this.slideCount;
        if (currentSlide + 1 < slideCount) {
          this.setActiveSlide(currentSlide + 1);
        } else {
          this.setActiveSlide(0);
        }

        setTimeout(function () {
          $(e.target).removeClass('inactive');
        }, 500);
      }
      this.adjustTabindex();
    });

    $('.home-carousel-next').on('keydown', e => {
      if (e.keyCode === 13) {
        this.adjustTabindex();
      }
    });

    $('.home-carousel-prev').on('click', e => {
      if (!$(e.target).hasClass('inactive')) {
        $(e.target).addClass('inactive');

        const currentSlide = this.currentSlide;
        const slideCount = this.slideCount;
        if (currentSlide > 0) {
          this.setActiveSlide(currentSlide - 1);
        } else {
          this.setActiveSlide(slideCount - 1);
        }

        setTimeout(function () {
          $(e.target).removeClass('inactive');
        }, 500);
      }
      this.adjustTabindex();
    });

    //added listener for enter key to adjust thumbnail indicies
    $('.home-carousel-prev').on('keydown', e => {
      if (e.keyCode === 13) {
        this.adjustTabindex();
      }
    });

    this.initializeThumbnailEvents();
    $('.bcg-thumbnail-carousel').on('breakpoint', () => this.initializeThumbnailEvents());
  }

  initializeThumbnailEvents () {
    $('.bcg-thumbnail-carousel-slide')
      .off('click keydown')
      .on('click', this.handleThumbnailClick)
      .on('keydown', e => {
        //32 is space, 13 is enter
        if (e.keyCode === 32 || e.keyCode === 13) {
          e.preventDefault();
          this.handleThumbnailClick(e);
        }
      });
  }

  handleThumbnailClick (e) {
    e.stopPropagation();
    e.stopImmediatePropagation();

    const index = e.currentTarget.getAttribute('data-slide-index') - 1;
    this.setActiveSlide(index);
    setTimeout(() => {
      // Load video
      const $slide = $('.home-carousel-video[data-slide-index=' + (index + 1) + ']');
      if ($slide.attr('data-video-id')) {
        this.currentVideoSlideIndex = index + 1;
        this.loadVideo($slide.attr('data-video-id'), '.home-carousel-video');
      }
    }, 100);
  }

  adjustTabindex () {
    //makes all visible thumbnails tabable
    const activeSlides = $('.bcg-thumbnail-carousel .slick-active[aria-hidden="false"]');
    const activeSlideLength = activeSlides.length;
    for (let i = 0; i < activeSlideLength; i++) {
      $(activeSlides[i]).attr('tabindex', '0');
    }
    //ensure that all hidden slides are not tabbable
    const hiddenSlides = $('.bcg-thumbnail-carousel .slick-slide[aria-hidden="true"]');
    const hiddenSlideLength = hiddenSlides.length;
    for (let i = 0; i < hiddenSlideLength; i++) {
      $(hiddenSlides[i]).attr('tabindex', '-1');
    }
  }

  resizeVideos () {
    $('.home-carousel-video').each(function () {
      const $videoContainer = $(this);
      $videoContainer.css('height', '');
      $videoContainer.css('width', '');
      const $video = $(this).find('.bc-player');
      const width = $videoContainer.innerWidth();
      const height = width / VIDEO_ASPECT_RATIO;
      $video.innerHeight(height + 'px');
      $video.innerWidth(width + 'px');

      const $image = $(this).find('.bc-image-loader');
      $image.innerHeight(height + 'px');
    });
  }

  loadVideo (videoId, containingClass, $slide) {
    const url = window.baseUrl + '/api/video/' + videoId;
    $.ajax(url, {
      dataType: 'json',
      success: data => {
        this.loadSlideVideoPlayer(data, containingClass, $slide);
      },
      complete: function (xhr) {
        if (xhr.status === 401) {
          window.location.replace(window.baseUrl + '/login?redirect=' + encodeURIComponent(window.location.href));
        }
      },
    });
  }

  /**
   *
   * @param data
   * @param containingClass
   * @param $slide - Optional param for the video container.
   *                 Required for mobile playback because slick has duplicate slides with the same data-slide-index.
   *                 we need to know which to load the player into.
   */
  loadSlideVideoPlayer (data, containingClass, $slide) {
    if (!data || !data.result) {
      return;
    }

    this.currentVideo = data.result;
    this.loadTemplate(utils.getPlayerTemplateUrl(), template => {
      const html = template({
        url: window.location.href,
        video: this.currentVideo,
        isSecure: window.location.protocol === 'https:',
        'user-agent': window.navigator.userAgent,
        autoplay: false,
        playerBackground: window.playerBackground,
        subPath: window.subPath,
        player: window.bc_gallery.player,
        site: window.site,
      });
      let $videoContainer;

      if ($slide) {
        $videoContainer = $slide;
      } else {
        $videoContainer = $(containingClass + '[data-slide-index=' + this.currentVideoSlideIndex + ']');
      }

      // This case handles 1 video in collection
      if ($videoContainer.length === 0) {
        if (this.currentVideoSlideIndex === '1') {
          $videoContainer = $(containingClass);
        }
      }
      $videoContainer.find('.bc-player-placeholder').hide();
      if ($videoContainer.length) {
        teardownPlayers();
        $videoContainer.find('.bc-player').html(html)
          .show();
        setupPlayers($videoContainer.get(0));
        const source = this.currentVideo.source;
        // If the video is from Youtube. This autoplays video before it loads
        // So it throws 1002 error (video not found).
        if (window.videojs && window.videojs.players &&
          window.videojs.players.performPlayer && source !== 'youtube') {
          window.videojs.players.performPlayer.play();
        }
      }
      //Wait for the play button to be present before it is focused
      setTimeout(function () {
        $('.vjs-play-control').focus();
      }, 1000);
      this.onWindowResize();
    });
  }

  onAfterSlideChange (index) {
    if (this.previousSlideIndex !== index) {
      this.deleteVideos();
      this.previousSlideIndex = index;
    }
  }

  setActiveSlide (index) {
    if ($('.bcg-thumbnail-carousel-slide').length > 0) {
      $('.bcg-thumbnail-carousel-slide').removeClass('bcg-thumbnail-carousel-slide-active');
      $('.bcg-thumbnail-carousel-slide[data-slide-index=' + (index + 1) + ']').addClass('bcg-thumbnail-carousel-slide-active');

      $('.home-carousel-video, .home-carousel-info').removeClass('bcg-video-active');
      $('.home-carousel-video').removeClass('bcg-fade-in-video');
      $('.home-carousel-info').removeClass('bcg-fade-in-info');
      $('.home-carousel-video[data-slide-index=' + (index + 1) + ']').addClass('bcg-video-active');
      $('.home-carousel-info[data-slide-index=' + (index + 1) + ']').addClass('bcg-video-active');

      setTimeout(function () {
        $('.home-carousel-video[data-slide-index=' + (index + 1) + ']').addClass('bcg-fade-in-video');
        $('.home-carousel-info[data-slide-index=' + (index + 1) + ']').addClass('bcg-fade-in-info');
      }, 10);

      this.makeActiveThumbnailVisible(index);

      this.currentSlide = index;
      this.deleteVideos();
      window.bcGallery.imageLoader.loadImages();
    }
  }

  makeActiveThumbnailVisible (index) {
    const visibleThumbnailCount = VISIBLE_THUMBNAIL_COUNT;
    const thumbnailCarousel = $('.bcg-thumbnail-carousel');
    if (!thumbnailCarousel || thumbnailCarousel.length === 0) {
      return;
    }

    const currentIndex = thumbnailCarousel.slick('slickCurrentSlide');
    const slideCount = this.slideCount;

    if (index > (currentIndex + visibleThumbnailCount - 1) % slideCount) {
      thumbnailCarousel.slick('slickGoTo', index - visibleThumbnailCount + 1);
    } else if (index < currentIndex) {
      thumbnailCarousel.slick('slickGoTo', index);
    }
  }

  deleteVideos () {
    teardownPlayers();
    $('.home-carousel-video').each(function () {
      const $video = $(this);
      $video.find('.bc-player').html('')
        .hide();
      $video.find('.bc-player-placeholder').show();
    });

    this.onWindowResize();
  }

  loadTemplate (url, callback) {
    if (this.templates[url]) {
      return callback(this.templates[url]);
    }

    $.ajax({
      url: url,
      success: contents => {
        this.templates[url] = window.bcGallery.Handlebars.compile(contents);
        callback(this.templates[url]);
      },
      complete: function (xhr) {
        if (xhr.status === 401) {
          window.location.replace(window.baseUrl + '/login?redirect=' + encodeURIComponent(window.location.href));
        }
      },
    });
  }

  adjustNavArrows () {
    if (!(window.site.dynamicCustom && window.site.dynamicCustom.hideCarouselThumbnails)) {
      const videoCount = $('.bcg-home-carousel-container > .home-carousel-video').length;
      if (videoCount <= VISIBLE_THUMBNAIL_COUNT) {
        $('.home-carousel-next, .home-carousel-prev').hide();
      }
    }
  }

  onWindowResize () {
    this.resizeVideos();// order matters
    this.adjustNavArrows();
    clearTimeout(this.resizeTimeout);
    this.resizeTimeout = setTimeout(() => {
      this.resizeVideos();// order matters
    }, 100);
  }
}

//---------------------------------------------------------------------------------
// Video Detail
class Detail {

  init () {
    $('.bcg-video-detail-tab').on('click', e => {
      if (!$(e.currentTarget).hasClass('bcg-video-detail-tab-active')) {
        this.switchTab($('.bcg-video-detail-tab'), $(e.currentTarget));
      }
    });

    $('[role="tab"]').on('keydown', e => {
      const $original = $(e.currentTarget);
      const $next = $original.next('[role="tab"]');
      const $prev = $original.prev('[role="tab"]');
      let $target;

      /*
         Keycode 9 is the tab key. This check blocks
         the up and down keys from moving the page when they
         switch tabs.
       */
      if (e.keyCode !== 9) {
        e.preventDefault();
      }

      switch (e.keyCode) {
        //37 === left arrow, 38 === up arrow
        case 37:
        case 38:
          $target = $prev;
          break;
        //39 === right arrow, 40 === down arrow
        case 39:
        case 40:
          $target = $next;
          break;
        default:
          $target = false;
          break;
      }

      if ($target.length) {
        $original.attr({
          'tabindex': '-1',
          'aria-selected': null,
        });
        $target.attr({
          'tabindex': '0',
          'aria-selected': true,
        });
        this.switchTab($original, $target);
      }

    });

    $('.bcg-video-detail-description-view-more').on('click', () => {
      const $description = $('.bcg-video-detail-description > p');
      $description.attr('data-open', 'true');
      this.updateViewMore();
    });

    $('.bcg-video-detail-description-view-less').on('click', () => {
      const $description = $('.bcg-video-detail-description > p');
      $description.attr('data-open', 'false');
      this.updateViewMore();
    });

    // get URL as string
    const currentURL = (' ' + window.location)
      .slice(1)
      .replace('autoStart=true', '')
      .replace('?&', '?');

    // remove auto start from the share link
    $('.bcg-share-link').val(currentURL);

    $('.share-copy-link')
      .on('click', this.copyToClipboard)
      .on('keydown', (event) => {
        if (event.keyCode === 13) { // "Enter" key
          this.copyToClipboard();
        }
      });

    this.updateViewMore();
  }

  copyToClipboard () {
    const videoDetailURL = document.getElementsByClassName('bcg-share-link')[0];
    videoDetailURL.select();
    document.execCommand('copy');
  }

  switchTab (from, to) {
    //change focus
    $(from).removeClass('bcg-video-detail-tab-active');
    $(to).addClass('bcg-video-detail-tab-active');
    $(to).focus();

    //change aria-selected attribute
    $(from).removeAttr('aria-selected');
    $(to).attr('aria-selected', true);

    //hide & show relevant content
    $('.bcg-video-detail-container').hide();
    $('#' + $(to).attr('id') + 'C').fadeIn('slow');
  }

  updateViewMore () {
    const $description = $('.bcg-video-detail-description > p');
    if (window.site.dynamicCustom.showFullVideoDescription) {
      $description.attr('data-open', 'true');
    } else {
      const showViewMore = $description.prop('scrollHeight') > $description.height();
      const screenWidth = $(window).width();
      let maxHeight;
      if (screenWidth > breakpointTablet) {
        maxHeight = 32 * 3;
      } else if (screenWidth > breakpointMobile) {
        maxHeight = 28 * 3;
      } else {
        maxHeight = 24 * 3;
      }
      const showViewLess = !showViewMore && $description.height() > maxHeight;
      $('.bcg-video-detail-description-view-more').toggle(showViewMore);
      $('.bcg-video-detail-description-view-less').toggle(showViewLess);
    }
  }

  onWindowResize () {
    header.adjustMenu();
    this.resizeVideoPlayer();
    this.adjustVideoDetailHeight();
    this.updateViewMore();
  }

  resizeVideoPlayer () {
    const $video = $('.video-detail-video');
    const $videoContainer = $('.bcg-video-container');

    const screenWidth = $(window).width();
    let targetWidth = $videoContainer.width();

    if (screenWidth > breakpointDesktop && !(window.site.dynamicCustom && window.site.dynamicCustom.burgerMenu)) {
      targetWidth = $videoContainer.width();
    } else if (screenWidth > breakpointDesktop) {
      targetWidth = 840;
    } else if (screenWidth > breakpointTablet) {
      targetWidth = 730;
    }

    const targetHeight = targetWidth / VIDEO_ASPECT_RATIO;

    $video.width(targetWidth);
    $video.height(targetHeight);
    main.adjustSiteContainerHeight();
  }

  adjustVideoDetailHeight () {
    const $rightColumn = $('.bcg-video-detail-right-column');
    $('.video-detail').css('min-height', $rightColumn.height() + 49);
  }
}

class VideoGrid {
  constructor () {
    this.templates = [];
    this.nextPage = window.BCLS && window.BCLS.videos && window.BCLS.videos.nextPage;
  }

  init () {
    $('.video-grid-item-more').click(() => {
      const searchQuery = main.getParameterByName('q');
      if (searchQuery || searchQuery === '') {
        //do not pass any category if doing a site wide search. Empty string is a valid search query
        return this.loadVideos(null, searchQuery);
      }
      if (window.category && window.category.slug) {
        return this.loadVideos(window.category.slug);
      }
    });

    //triggers load more option on enter key press, mainly for keyboard users
    $('.video-grid-item-more').on('keydown', e => {
      if (e.keyCode === 13) {// enter key
        const searchQuery = main.getParameterByName('q');
        if (searchQuery || searchQuery === '') {
          //do not pass any category if doing a site wide search. Empty string is a valid search query
          return this.loadVideos(null, searchQuery);
        }
        if (window.category && window.category.slug) {
          return this.loadVideos(window.category.slug);
        }
      }
    });
  }

  loadTemplate (url, callback) {
    if (this.templates[url]) {
      return callback(this.templates[url]);
    }

    $.ajax({
      url: url,
      success: contents => {
        this.templates[url] = window.bcGallery.Handlebars.compile(contents);
        callback(this.templates[url]);
      },
      complete: function (xhr) {
        if (xhr.status === 401) {
          window.location.replace(window.baseUrl + '/login?redirect=' + encodeURIComponent(window.location.href));
        }
      },
    });
  }

  loadVideos (category, searchQuery) {
    const page = this.nextPage;
    const params = {
      page: page,
    };

    if (searchQuery || searchQuery === '') {
      params.q = searchQuery;
    }
    if (category) {
      params.category = category;
    }
    let route = '';

    // recursively loop through categories to find active category
    function recurse (category) {
      if (category.slug === params.category) {
        window.category = category;
        route = '/' + category.slug;
        return;
      }

      if (_.has(category, 'children')) {
        _.each(category.children, function (child) {
          recurse(child);
        });
      }
    }

    if (params.category) {
      _.each(window.categories, function (category) {
        recurse(category);
      });
    }

    if (params.page) {
      window.query.page = params.page;
    }

    if (params.q) {
      window.query.q = params.q;
    }

    if (params.sort_by) {
      window.query.sort_by = params.sort_by;
    } else {
      const sortMethod = getSortMethod();
      params.sort_by = sortMethod;
    }

    return $.ajax(window.baseUrl + '/api/videos' + route, {
      dataType: 'json',
      data: params,
      success: data => {
        if (!data || !data.result || !data.result.items || data.result.items === 0) {
          return;
        }
        const videos = data.result.items;
        this.nextPage = data.result && data.result.nextPage;
        const videoGridItemPath = window.bcGallery.getTemplatePath('/sites/catalogue/partials/videoGridItem.hbs');

        this.loadTemplate(videoGridItemPath, template => {
          for (let i = 0; i < videos.length; i++) {
            if (videos[i]) {
              const contextData = {
                baseUrl: window.baseUrl,
                imageTranscoder: window.BCLS.imageTranscoder,
                category: window.category,
                query: window.query,
                subPath: window.subPath,
              };
              const handlebarContext = _.extend({}, videos[i], contextData);
              $('.video-grid').append(template(handlebarContext));
            }
          }
          $('.video-grid').append($('.video-grid-item-more'));
          window.bcGallery.imageLoader.loadImages();

          if (!this.nextPage) {
            $('.video-grid-item-more').hide();
          }
        });
      },
      complete: function (xhr) {
        if (xhr.status === 401) {
          window.location.replace(window.baseUrl + '/login?redirect=' + encodeURIComponent(window.location.href));
        }
      },
    });
  }
}

const main = new Main();
const carousel = new Carousel();
const detail = new Detail();
const header = new Header();
const videoGrid = new VideoGrid();

$(function () {
  main.init();
  carousel.init();
  detail.init();
  // BCCLASSIC.Share.init();
  header.init();
  videoGrid.init();

  main.onWindowResize();
  carousel.onWindowResize();
  detail.onWindowResize();
  header.onWindowResize();

  // always load images in 16:9 ratio, helps IE 11
  window.bcGallery.imageLoader.ignoreHeight = true;
  window.bcGallery.imageLoader.loadImages();

  const width = $(window).width();

  if (width <= breakpointTablet) {
    main.mobileImagesLoaded = true;
  } else {
    main.desktopImagesLoaded = true;
  }

  // the carousel dots need to repositioned after images are loaded
  window.bcGallery.imageLoader.loaded = function () {
    carousel.onWindowResize();
  };
});

let windowResizeTimeout;
$(window).resize(function () {
  window.clearTimeout(windowResizeTimeout);
  windowResizeTimeout = setTimeout(function () {
    main.onWindowResize();
    carousel.onWindowResize();
    detail.onWindowResize();
    header.onWindowResize();
  }, 100);
});

$(window).on('load', function () {
  header.onWindowResize();
});

window.BCLS = window.BCLS || {};
window.BCLS.config = {
  reloadOnChange: false,
};
window.BCLS.afterReadyHandler = function () {
  carousel.onWindowResize();
  window.BCLS.modVP.play();
};

window.BCLS.performOverrideAutoplay = function () {
  if (window.site.autoplayNext) {
    //this is called on the home page when autoplaying carousel videos
    //first we need to figure out what video is currently playing
    let slideIndex;

    const width = $(window).width();

    if (width > breakpointTablet) {
      slideIndex = $('.bcg-thumbnail-carousel-slide-active').data('slide-index');
    } else {
      slideIndex = $('.slick-center .home-carousel-slide').data('slide-index');
    }

    if (slideIndex) {
      slideIndex++;
      const homeCarousel = $('.home-carousel');
      homeCarousel.slick('slickNext');

      if (width < breakpointTablet) {
        //this needs to be a function because it needs to execute after the slide change animation has completed
        main.autoplayMobile = function () {
          $('.home-carousel-slide[data-slide-index="' + slideIndex + '"]').find('.bc-player-placeholder')
            .click(); //play the next video
        };
        return;
      }
      //otherwise click the next video in the thumnnail carousel after a breif period
      setTimeout(function () {
        if (width >= breakpointTablet) {
          $('*[data-slide-index="' + slideIndex + '"]').click(); //play the next video
        }
      }, 150);
    }
  }
};
