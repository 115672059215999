// Helpers for rendering site templates.
// Shared between client and server, but only for rendering sites.
const Handlebars = require('handlebars');
const _ = require('underscore');
const utils = require('./utils');
const config = require('../../config/client');

module.exports = (createLogger) => {
  let logger = console;
  if (createLogger) {
    logger = createLogger(module);
  } else {
    global.bcGallery.Handlebars = Handlebars;
  }

  const secureFilters = require('secure-filters');
  const constants = require('../constants');

  function tInjectFunction (str, options) {
    if (str) {
      Object.keys(options.hash).forEach(function (key) {
        //need to inject the value of the key into the translation string
        str = str.replace('__' + key + '__', options.hash[key]);
      });
      return new Handlebars.SafeString(str);
    } else {
      return '';
    }
  }

  const thumbnailTemplate = Handlebars.compile(
    '<a href="#id/{{id}}" class="video-link-wrapper" data-video-id="{{id}}" data-video-related-url="{{relatedUrl}}">' +
    '<div class="video-thumbnail-hover"></div>' +
    '<div class="video-thumbnail-name">{{name}}</div>' +
    '<div class="video-thumbnail-timestamp">{{timestamp}}</div>' +
    '<i class="icon-play fa fa-regular fa-circle-play"></i>' +
    '<div class="video-thumbnail-wrapper">' +
    '{{#if useImageLoader}}' +
    '{{#if isBackgroundImage}}' +
    '<div data-bc-src="{{image}}" class="video-thumbnail bc-image-loader" data-bc-background="true"></div>' +
    '{{else}}' +
    '<img data-bc-src="{{image}}" class="video-thumbnail bc-image-loader" />' +
    '{{/if}}' +
    '{{else}}' +
    '{{#if isBackgroundImage}}' +
    '<div class="video-thumbnail" style="background-image: url({{image}})"></div>' +
    '{{else}}' +
    '<img src="{{image}}" class="video-thumbnail" />' +
    '{{/if}}' +
    '{{/if}}' +
    '</div></a>'
  );

  function isHomePage (site) {
    return !!(site && site.activePage === 'index');
  }

  function isCategoryPage (site, category) {
    return !!(site && category && site.activePage === 'category');
  }

  Handlebars.registerHelper('ifHomepageOrDetail', function (options) {
    const site = options && options.data && options.data.root ? options.data.root.site : null;

    if (site && (site.activePage === 'index' || site.activePage === 'detail')) {
      return options.fn(this);
    }
    return options.inverse(this);
  });

  Handlebars.registerHelper('isDetailPage', function (options) {
    const site = options && options.data && options.data.root ? options.data.root.site : null;

    return site && site.activePage === 'detail';
  });

  Handlebars.registerHelper('escapeJs', function (str) {
    if (typeof str === 'string') {
      return new Handlebars.SafeString(str.replace(/[\'\"\\\/]/gm, function (c) {
        return '\\' + c;
      }));
    }
    return '';
  });

  /**
   * Register handlebars helper to translate with post processing. This allows passing in strings into a key lookup to print in values inside the string
   * Handler takes in the translation string (translation.key) as the first argument. String should have __injectionKey__ embedded inside
   * Then, pass in arguments matching the injection key to inject translations/variables into the translation string
   */
  Handlebars.registerHelper('tInject', tInjectFunction);

  Handlebars.registerHelper('ifDefined', function () {
    const options = arguments[arguments.length - 1];
    for (let i = 0; i < arguments.length - 1; ++i) {
      if (arguments[i] === undefined) {
        return options.inverse(this);
      }
    }
    return options.fn(this);
  });

  Handlebars.registerHelper('ifCond', function (v1, operator, v2, options) {
    switch (operator) {
      case '===':
        return (v1 === v2) ? options.fn(this) : options.inverse(this);
      case '<':
        return (v1 < v2) ? options.fn(this) : options.inverse(this);
      case '<=':
        return (v1 <= v2) ? options.fn(this) : options.inverse(this);
      case '>':
        return (v1 > v2) ? options.fn(this) : options.inverse(this);
      case '>=':
        return (v1 >= v2) ? options.fn(this) : options.inverse(this);
      case '&&':
        return (v1 && v2) ? options.fn(this) : options.inverse(this);
      case '||':
        return (v1 || v2) ? options.fn(this) : options.inverse(this);
      default:
        return options.inverse(this);
    }
  });

  Handlebars.registerHelper('or', function (v1, v2) {
    return v1 || v2;
  });

  Handlebars.registerHelper('not', function (v1) {
    return !v1;
  });

  Handlebars.registerHelper('and', function (v1, v2) {
    return v1 && v2;
  });

  Handlebars.registerHelper('eq', function (first, second, options) {
    // firstKey and secondKey options allow for a lookup for a key in an object
    // if the first or second parameter is an object
    if (options.hash.firstKey) {
      first = first[options.hash.firstKey];
    }
    if (options.hash.secondKey) {
      second = second[options.hash.secondKey];
    }
    // firstAppend and secondAppend options allow for appending data to a value
    // for comparison
    if (options.hash.firstAppend) {
      first += '' + options.hash.firstAppend;
    }
    if (options.hash.secondAppend) {
      second += '' + options.hash.secondAppend;
    }
    if (first === second) {
      return options.fn(this);
    } else {
      return options.inverse(this);
    }
  });

  Handlebars.registerHelper('ne', function (first, second, options) {
    if (options.hash.roundDate) {
      options.hash.round = options.hash.roundDate;
      first = new Date(first).getTime();
      second = new Date(second).getTime();
    }
    // Support rounding to a certain granularity (used by sites index to determine
    // if a site has outstanding changes)
    if (options.hash.round) {
      first = Math.round(first / options.hash.round);
      second = Math.round(second / options.hash.round);
    }
    if (first !== second) {
      return options.fn(this);
    } else {
      return options.inverse(this);
    }
  });

  Handlebars.registerHelper('gt', function (first, second, options) {
    if (first > second) {
      return options.fn(this);
    } else {
      return options.inverse(this);
    }
  });

  Handlebars.registerHelper('lt', function (first, second, options) {
    if (first < second) {
      return options.fn(this);
    } else {
      return options.inverse(this);
    }
  });

  // Lookup the value of a key for an object. First argument is the object, any
  // other arguments are concatenated together to form the key as opposed to looking
  // into a nested object. There is actually a use case for this because Handlebars
  // doesn't support concatenation of strings to be passed as arguments
  Handlebars.registerHelper('lookup', function (item) {
    const key = Array.prototype.slice.call(arguments, 1, arguments.length - 1);
    return item[key.join('')];
  });

  const videoThumbnailHelper = function (video, options) {
    const height = options.hash['height'];
    const width = options.hash['width'];
    const categorySlug = options.hash['category'];
    const baseUrl = options.hash['baseUrl'];
    const id = video ? video.id : '';
    const name = video ? video.name : '';
    const relatedUrl = baseUrl + '/api/videos/' + categorySlug + '/video/' + id + '/related';

    let timestamp = '0:0';

    if (video) {
      const hours = parseInt(video.length / 1000 / 60 / 60, 10);
      const minutes = parseInt(video.length / 1000 / 60 % 60, 10);
      let strMinutes = minutes.toFixed(0);
      if (minutes < 10) {
        strMinutes = '0' + strMinutes;
      }
      const seconds = parseInt(video.length / 1000 % 60, 10);
      let strSeconds = seconds.toFixed(0);
      if (seconds < 10) {
        strSeconds = '0' + strSeconds;
      }

      if (hours === 0 && minutes === 0) {
        timestamp = '0:' + strSeconds;
      } else if (hours === 0) {
        timestamp = minutes + ':' + strSeconds;
      } else {
        timestamp = hours + ':' + strMinutes + ':' + strSeconds;
      }

      if (video.length < 0) {
        const translations = options.data.root.translations || window.translations || {};
        //we have a live event
        timestamp = translations['LIVE'] || 'LIVE';
      }
    }

    let image = video.galleryThumbnailURL;
    if (options.data.root.screenshot) {
      image = 'https://s3.amazonaws.com/constellation-test-east/photos/' +
        options.data.root.template.id.replace(/_responsive/g, '') + '/images/' + video.galleryThumbnailURL;
    }

    if (options.hash['useBCImageLoader'] !== true) {
      image = options.data.root.imageTranscoder + '?image=' + encodeURIComponent(image) + '&width=' + width + '&height=' + height;
    }

    const html = thumbnailTemplate({
      id: id,
      relatedUrl: relatedUrl,
      name: name,
      timestamp: timestamp,
      image: image,
      useImageLoader: options.hash.useBCImageLoader === true,
      isBackgroundImage: options.hash.isBackgroundImage,
    });

    return new Handlebars.SafeString(html);
  };

  Handlebars.registerHelper('video_thumbnail_responsive', function (video, options) {
    return videoThumbnailHelper(video, options);
  });

  Handlebars.registerHelper('with_at', function (collection, index, options) {
    let context;
    if (_.isObject(collection) && _.isArray(collection.items)) {
      context = collection.items[index];
    } else if (_.isObject(collection)) {
      if (!collection[index]) {
        return;
      }
      context = collection[index];
    } else if (_.isArray(collection)) {
      if (!collection[index]) {
        return;
      }
      context = collection[index];
    }

    if (options.hash) {
      _.extend(context, options.hash);
    }

    if (context) {
      if (_.isFunction(context)) {
        context = context.call(this);
      }

      if (!_.isEmpty(context)) {
        return options.fn(context);
      }
    }
  });

  Handlebars.registerHelper('next', function (collection, options) {
    let context = {};
    if (_.isObject(collection) && _.isArray(collection.items)) {
      context = collection.items.shift();
    } else if (_.isArray(collection)) {
      context = collection.shift();
    }
    if (!context) {
      return '';
    }
    return options.fn(context);
  });

  Handlebars.registerHelper('transcode', function (src, options) {
    const data = options.hash;
    if (!data.height && !data.width) {
      return src;
    }
    src = options.data.root.imageTranscoder + '/?image=' + encodeURIComponent(src);
    if (data.max_dimension) {
      src += '&max_dimension=' + data.max_dimension;
    } else {
      if (data.width) {
        src += '&width=' + data.width;
      }
      if (data.height) {
        src += '&height=' + data.height;
      }
    }
    if (data.format) {
      src += '&format=' + data.format;
    }
    if (data.crop) {
      src += '&crop=' + data.crop;
    }
    if (data.quality) {
      src += '&quality=' + data.quality;
    }
    return src;
  });

  Handlebars.registerHelper('set', function () {
    const args = Array.prototype.slice.call(arguments, 0);
    args.pop();
    const key = args.shift();
    while (!this[key] && args.length) {
      this[key] = args.shift();
    }
    return '';
  });

  Handlebars.registerHelper('captureAs', function (name, options) {
    this[name] = options.fn(this);
    return '';
  });

  Handlebars.registerHelper('default', function () {
    const args = Array.prototype.slice.call(arguments, 0);
    args.pop();
    let value;
    while (args.length && !value) {
      value = args.shift();
    }
    return value;
  });

  Handlebars.registerHelper('plusOne', function (zeroIndexed) {
    return parseInt(zeroIndexed, 10) + 1;
  });

  Handlebars.registerHelper('isOrHasChildCategory', function (category, childCategory, options) {
    // false on empty arguments
    if (!category || !category.id || !childCategory || !childCategory.id) {
      return options.inverse(this);
    }

    // is a direct match
    if (category.id === childCategory.id) {
      return options.fn(this);
    }

    // else, check if childCategory is a descendant
    const children = category ? category.children : null;
    if (!children) {
      return options.inverse(this);
    }

    let tempChild = null;
    for (let i = 0; i < children.length; i++) {
      tempChild = children[i];
      if (tempChild.id === childCategory.id) {
        return options.fn(this);
      }
    }

    return options.inverse(this);
  });

  Handlebars.registerHelper('hasChildCategories', function (category, options) {
    if (!category || !category.children || category.children.length === 0) {
      return options.inverse(this);
    }

    return options.fn(this);
  });

  Handlebars.registerHelper('isInActivePath', function (category, activePath, options) {
    // false on empty arguments
    if (!category || !category.id || !activePath || activePath.length === 0) {
      return options.inverse(this);
    }

    const isActive = activePath.some(pathItem => pathItem?.id === category.id);

    return isActive ? options.fn(this) : options.inverse(this);
  });

  Handlebars.registerHelper('setAsVideosFromCategory', function (key, categories, targetCategoryId, options) {
    if (!key || !categories || !targetCategoryId) {
      return '';
    }

    // need to search child categories as well so flatten the list first,
    // the following assumes there is only 1 level of nesting for categories,
    let i = 0;
    let j = 0;
    let k = 0;
    let flattenedCategories = [];
    for (i = 0; i < categories.length; i++) {
      flattenedCategories.push(categories[i]);
      if (categories[i].children && categories[i].children.length > 0) {
        flattenedCategories = flattenedCategories.concat(categories[i].children);
        for (j = 0; j < categories[i].children.length; j++) {
          //support third level of nesting (needed from the multi level nesting update we did a while back
          if (categories[i].children[j].children && categories[i].children[j].children.length > 0) {
            flattenedCategories = flattenedCategories.concat(categories[i].children[j].children);
            for (k = 0; k < categories[i].children[j].children.length; k++) {
              //support fourth level of nesting (needed from the multi level nesting update we did a while back
              if (categories[i].children[j].children[k].children && categories[i].children[j].children[k].children.length > 0) {
                flattenedCategories = flattenedCategories.concat(categories[i].children[j].children[k].children);
              }
            }
          }
        }
      }
    }

    // search flattened category list for the desired category
    let targetCategory = null;
    for (i = 0; i < flattenedCategories.length; i++) {
      if (flattenedCategories[i].id === targetCategoryId) {
        targetCategory = flattenedCategories[i];
      }
    }

    // no need to proceed if we cannot find the specified category
    let targetCategoryVideos = targetCategory && targetCategory.videos ? targetCategory.videos.items : null;
    if (!targetCategoryVideos) {
      return '';
    }

    // "excludeVideoId" option is used to make sure we don't include the current video
    // within the list of "similar" videos
    // NOTE: This is not used. Not sure why
    /*const excludeVideoId = options.hash && options.hash.excludeVideoId ? options.hash.excludeVideoId : null;
     const removed = null;
     if (excludeVideoId) {
     for (i=targetCategoryVideos.length-1; i>=0; i--) {
     if (targetCategoryVideos[i].id === excludeVideoId) {
     removed = targetCategoryVideos.splice(i, 1);
     }
     }
     }*/

    // "randomize" option is used to keep the "similar" videos "fresh"
    const randomize = options.hash && options.hash.randomize;
    if (randomize) {
      let tempIndex = 0;
      let tempVideo = null;
      for (i = targetCategoryVideos.length - 1; i >= 0; i--) {
        tempIndex = Math.floor(Math.random() * i);

        tempVideo = targetCategoryVideos[i];
        targetCategoryVideos[i] = targetCategoryVideos[tempIndex];
        targetCategoryVideos[tempIndex] = tempVideo;
      }
    }

    // "max" option allows for limiting the number of videos set on the specified key
    const max = options.hash && options.hash.max ? options.hash.max : targetCategoryVideos.length;
    targetCategoryVideos = targetCategoryVideos.slice(0, max);

    this[key] = targetCategoryVideos;

    return '';
  });

  Handlebars.registerHelper('setAsBaseUrlFromUrl', function (key, urlString, options) {
    // url on index page has "linkBaseUrl" queryString which interferes with social sharing links
    // this may be a redundant function but is used by the editorial tmeplate homepage carousel
    let localUrl = require('url');
    const parsedUrl = localUrl.parse(urlString);

    if (parsedUrl) {
      const protocol = parsedUrl.protocol;
      const host = parsedUrl.host;
      const subPath = options.data.root.subPath || '';

      this[key] = protocol + '//' + host + subPath;
    }

    localUrl = null;
    return '';
  });

  Handlebars.registerHelper('shareSpecificLink', function (service, options) {
    const videoPath = Handlebars.helpers.video_path(options.hash['video'], options);
    options.hash.url = videoPath;
    return Handlebars.helpers.shareLink(service, options);
  });

  Handlebars.registerHelper('shareLink', function (service, options) {
    function tokenToRegex (tokenString) {
      let regexString = tokenString;
      regexString = regexString.replace(/\[\[/g, '\\\[\\\[');
      regexString = regexString.replace(/\]\]/g, '\\\]\\\]');
      return new RegExp(regexString, 'g');
    }

    function substituteShareTokens (message, video, videoUrl) {
      if (!video) {
        return message;
      }

      // avoid double-encoding any URLs passed to this function
      // e.g. urlToShare is already encoded
      if (decodeURIComponent(videoUrl) !== videoUrl) {
        videoUrl = decodeURIComponent(videoUrl);
      }

      let replaced = message;
      replaced = replaced.replace(tokenToRegex(constants.SHARE_TOKENS.VIDEO_URL), videoUrl);
      replaced = replaced.replace(tokenToRegex(constants.SHARE_TOKENS.VIDEO_TITLE), video.name);
      replaced = replaced.replace(tokenToRegex(constants.SHARE_TOKENS.VIDEO_SHORT_DESC), video.shortDescription || '');
      replaced = replaced.replace(tokenToRegex(constants.SHARE_TOKENS.VIDEO_RELATED_LINK_URL), video.linkURL || '');
      return replaced;
    }

    function safeCheckShareMediaKey (key) {
      if (options.hash && options.hash.site && options.hash.site.share && options.hash.site.share.media) {
        return options.hash.site.share.media[key];
      }
      return false;
    }

    function getSiteUrl (url) {
      if (options.hash.site.template && options.hash.site.template === 'designers_responsive' && url.indexOf('#') === -1 && options.hash.video) {
        url = url.replace('/detail/', '/category/');
        url += '#id/' + options.hash.video.id;
      }
      return encodeURIComponent(url);
    }
    const video = options.hash.video;
    let translations = options.data.root.translations;
    let urlToShare = getSiteUrl(options.hash.url);
    let message = '';

    if (options.hash.overrideUrl) {
      urlToShare = encodeURIComponent(options.hash.overrideUrl);
    }

    switch (service) {
      /* ------------------------ */
      case 'facebook': {
        if (!video) {
          return '';
        }
        const redirect = options.hash.site.redirect;
        const slugNum = parseInt(options.hash.site.slug.split('-')[1]);
        let fbLink = 'https://www.facebook.com/dialog/share?' +
          'app_id=' + redirect.facebookAppId +
          '&display=popup' +
          '&href=' + urlToShare +
          '&caption=' + encodeURIComponent('From ' + options.hash.site.name) +
          '&name=' + encodeURIComponent(video.name) +
          '&picture=' + encodeURIComponent(video.galleryThumbnailURL) +
          '&redirect_uri=' + encodeURIComponent(redirect.baseUrl + 'fb/' + slugNum + '?redirect=' + encodeURIComponent(redirect.redirectUrl));

        if (safeCheckShareMediaKey('facebookMsgEnabled') && safeCheckShareMediaKey('facebookCustomMsg')) {
          message = safeCheckShareMediaKey('facebookCustomMsg');
          message = substituteShareTokens(message, video, urlToShare);
          fbLink += '&description=' + encodeURIComponent(message);
        } else if (video.shortDescription) {
          fbLink += '&description=' + encodeURIComponent(video.shortDescription);
        }

        return fbLink;
      }
      /* ------------------------ */
      case 'twitter': {
        let shareText = '';
        if (safeCheckShareMediaKey('twitterMsgEnabled') && safeCheckShareMediaKey('twitterCustomMsg')) {
          message = safeCheckShareMediaKey('twitterCustomMsg');
          message = substituteShareTokens(message, video, encodeURI(decodeURIComponent(urlToShare)));
          shareText = encodeURIComponent(message);
        } else if (options.hash.customBody) {
          shareText = encodeURIComponent(options.hash.customBody.replace(/@@VIDEO_URL@@/g, decodeURIComponent(urlToShare)));
        } else {
          if (!translations) {
            translations = {};
          }

          shareText = encodeURIComponent(tInjectFunction(translations['share-twitter-default'], {
            hash: { videoURL: decodeURIComponent(urlToShare) },
          }));
        }

        let twitterLink = 'https://twitter.com/intent/tweet?text=' + shareText;
        if (options.hash.social) {
          if (options.hash.social.twitter) {
            twitterLink += '&via=' + options.hash.social.twitter;
            twitterLink += '&related=' + options.hash.social.twitter;
          }
        }
        return twitterLink;
      }
      /* ------------------------ */
      case 'gplus': {
        // custom share message is not possible with Google+
        return 'https://plus.google.com/share?url=' + urlToShare;
      }
      /* ------------------------ */
      case 'linkedin': {
        if (!video) {
          return '';
        }
        let linkedinLink = 'https://www.linkedin.com/shareArticle?mini=true&url=' + urlToShare + '&title=' + encodeURIComponent(video.name) + '&source=' + encodeURIComponent(options.hash.site.name);

        if (safeCheckShareMediaKey('linkedinMsgEnabled') && safeCheckShareMediaKey('linkedinCustomMsg')) {
          message = safeCheckShareMediaKey('linkedinCustomMsg');
          message = substituteShareTokens(message, video, urlToShare);
          linkedinLink += '&summary=' + encodeURIComponent(message);
        } else if (video.shortDescription) {
          linkedinLink += '&summary=' + encodeURIComponent(video.shortDescription);
        }

        return linkedinLink;
      }
      /* ------------------------ */
      case 'tumblr': {
        if (!video) {
          return '';
        }
        const embed =
          '<object id="flashObj" width="640" height="360" classid="clsid:D27CDB6E-AE6D-11cf-96B8-444553540000" codebase="http://download.macromedia.com/pub/shockwave/cabs/flash/swflash.cab#version=9,0,47,0">' +
          '  <param name="movie" value="http://c.brightcove.com/services/viewer/federated_f9?isVid=1&isUI=1" />' +
          '  <param name="flashVars" value="videoId=' + video.id + '&linkBaseURL=' + decodeURIComponent(getSiteUrl(options.hash.url)) + '&playerID=' + video.sharePlayerId + '&domain=embed&dynamicStreaming=true" />' +
          '  <param name="base" value="http://admin.brightcove.com" />' +
          '  <param name="seamlesstabbing" value="false" />' +
          '  <param name="allowFullScreen" value="true" />' +
          '  <param name="swLiveConnect" value="true" />' +
          '  <param name="allowScriptAccess" value="always" />' +
          '  <embed src="http://c.brightcove.com/services/viewer/federated_f9?isVid=1&isUI=1" flashVars="videoId=' + video.id + '&linkBaseURL=' + decodeURIComponent(getSiteUrl(options.hash.url)) + '&playerID=' + video.sharePlayerId + '&domain=embed&dynamicStreaming=true" base="http://admin.brightcove.com" name="flashObj" width="480" height="270" seamlesstabbing="false" type="application/x-shockwave-flash" allowFullScreen="true" allowScriptAccess="always" swLiveConnect="true" pluginspage="http://www.macromedia.com/shockwave/download/index.cgi?P1_Prod_Version=ShockwaveFlash"></embed>' +
          '</object>';
        let tumblrLink = 'https://www.tumblr.com/share/video?embed=' + encodeURIComponent(embed);
        if (video.shortDescription) {
          tumblrLink += '&caption=' + encodeURIComponent(video.shortDescription);
        }
        return tumblrLink;
      }
      /* ------------------------ */
      case 'pinterest': {
        if (!video) {
          return '';
        }
        let pinterestLink = 'https://pinterest.com/pin/create/button/?url=' + urlToShare + '&media=' + encodeURIComponent(video.galleryThumbnailURL);
        if (safeCheckShareMediaKey('pinterestMsgEnabled') && safeCheckShareMediaKey('pinterestCustomMsg')) {
          message = safeCheckShareMediaKey('pinterestCustomMsg');
          message = substituteShareTokens(message, video, urlToShare);
          pinterestLink += '&description=' + encodeURIComponent(message);
        } else if (video.shortDescription) {
          pinterestLink += '&description=' + encodeURIComponent(video.shortDescription);
        }
        return pinterestLink;

      }
      /* ------------------------ */
      case 'email': {
        /* preserve original behavior to return blank string if video not present */
        if (!video) {
          return '';
        }

        let emailSubject = encodeURIComponent(video.name);
        if (safeCheckShareMediaKey('emailMsgEnabled') && safeCheckShareMediaKey('emailCustomSubj')) {
          let subject = safeCheckShareMediaKey('emailCustomSubj');
          subject = substituteShareTokens(subject, video, urlToShare);
          emailSubject = encodeURIComponent(subject);
        } else if (options.hash.customSubject) {
          emailSubject = encodeURIComponent(options.hash.customSubject);
        }

        let emailBody = (video.shortDescription ? encodeURIComponent(video.shortDescription) + '%0A%0A' : '') + urlToShare;
        if (safeCheckShareMediaKey('emailMsgEnabled') && safeCheckShareMediaKey('emailCustomMsg')) {
          message = safeCheckShareMediaKey('emailCustomMsg');
          message = substituteShareTokens(message, video, urlToShare);
          emailBody = encodeURIComponent(message);
        } else if (options.hash.customBody) {
          emailBody = encodeURIComponent(options.hash.customBody.replace(/@@VIDEO_URL@@/g, decodeURIComponent(urlToShare)));
        }

        return 'mailto:?subject=' + emailSubject + '&body=' + emailBody;
      }
      /* ------------------------ */
      // odnoklassniki (ok.ru) share service is for herbalife only
      case 'odnoklassniki': {
        if (!video) {
          return '';
        }
        return 'https://connect.ok.ru/offer?url=' + urlToShare + '&title=' + encodeURIComponent(video.name);
      }
      /* ------------------------ */
      // vk (vk.com) share service is for herbalife only
      case 'vk': {
        if (!video) {
          return '';
        }
        return 'http://vk.com/share.php?url=' + urlToShare + '&title=' + encodeURIComponent(video.name);
      }
      /*--------------------------*/
      //Whatsapp custom share service for marykay
      case 'whatsapp' : {
        if (!video) {
          return '';
        }
        return 'https://api.whatsapp.com/send?phone&text=' + encodeURIComponent(video.name) + '%20' + urlToShare;
      }
      /*--------------------------*/
      //Telegram custom share service for marykay
      case 'telegram' : {
        if (!video) {
          return '';
        }
        return 'https://telegram.me/share/url?url=' + urlToShare + '&text=' + encodeURIComponent(video.name);
      }
      /*--------------------------*/
      //Ok chat custom share service for marykay
      case 'ok' : {
        if (!video) {
          return '';
        }
        return 'https://connect.ok.ru/offer?url=' + urlToShare + '&title=' + encodeURIComponent(video.name);
      }
      /*--------------------------*/
      //Line chat custom share service for marykay
      case 'line' : {
        if (!video) {
          return '';
        }
        return 'https://social-plugins.line.me/lineit/share?url=' + urlToShare;
      }

    }

    return '';
  });

  Handlebars.registerHelper('followLink', function (service, options) {
    switch (service) {
      case 'facebook':
        return 'https://www.facebook.com/' + encodeURIComponent(options.hash.handle);

      case 'twitter':
        return 'https://twitter.com/' + encodeURIComponent(options.hash.handle);

      case 'gplus':
        return 'https://plus.google.com/' + options.hash.handle;

      case 'linkedin':
        return 'https://www.linkedin.com/' + options.hash.handle;

    }
  });

  Handlebars.registerHelper('isEmpty', function (obj, options) {
    // This needs to go into a utils module
    function hasTrueValue (obj) {
      let boolValue;
      _.each(obj, function (value) {
        boolValue = boolValue || value;
      });
      return boolValue;
    }

    if (!_.isObject(obj) || _.isEmpty(obj) || !hasTrueValue(obj)) {
      return options.fn(this);
    } else {
      return options.inverse(this);
    }
  });

  Handlebars.registerHelper('urlencode', function (text) {
    return encodeURIComponent(text);
  });

  Handlebars.registerHelper('stripPrefix', function (text) {
    // For intuitive template to remove version prefix
    return text.substring(text.indexOf('_') + 1);
  });

  Handlebars.registerHelper('category_path', function (category, options) {
    if (options) {
      let base = options.hash['baseUrl'] + '/';
      if (options.hash.query && options.hash.query.q) {
        return base + 'search?q=' + encodeURIComponent(options.hash.query.q);
      }
      if (options.hash['ajax']) {
        base = '#';
      }

      const qsTokens = [];
      if (options.hash.mobile) {
        qsTokens.push('device=mobile');
      }

      let qsString = '';
      if (qsTokens.length > 0) {
        qsString = '?' + qsTokens.join('&');
      }

      const category_slug = (category && category['slug'] || '');
      const url = base + 'category/videos/' + category_slug + qsString;
      return new Handlebars.SafeString(url);
    }
  });

  Handlebars.registerHelper('encode_uri', function (url) {
    return new Handlebars.SafeString(encodeURI(url));
  });

  Handlebars.registerHelper('log', function () {
    const args = Array.prototype.slice.call(arguments, 0);
    args.pop();
    args.unshift('handlebars log:');
    console.log.apply(console, args);
    return '';
  });

  Handlebars.registerHelper('each_upto', function (ary, max, options) {
    if (!ary || ary.length === 0) {
      return options.inverse(this);
    }
    const result = [];
    let data = null;
    for (let i = 0; i < max && i < ary.length; ++i) {
      data = Handlebars.createFrame(options.data || {});
      data.upto_index = i;
      data.upto_index_from_1 = (i + 1);

      if (options.hash) {
        _.extend(data, options.hash);
      }
      result.push(options.fn(ary[i], { data: data }));
    }
    return result.join('');
  });

  // Used to determine the direction of the caret in the video browser
  Handlebars.registerHelper('activeChildCategory', function (categories, slug, activeSlug, options) {
    if (!categories || !categories.length) {
      return options.inverse(this);
    }
    if (slug === activeSlug) {
      return options.fn(this);
    }
    const activeCategory = _.findWhere(categories, {
      slug: activeSlug,
    });
    if (activeCategory) {
      return options.fn(this);
    }
    return options.inverse(this);
  });

  Handlebars.registerHelper('video_path', function (video, options) {
    if (!video) {
      return;
    }

    let baseUrl = '';
    if (options.hash['baseUrl'] === '' || options.hash['baseUrl']) {
      baseUrl = options.hash['baseUrl'];
    } else if (video['baseUrl']) {
      baseUrl = video['baseUrl'];
    }

    const qs = {};

    if (options.hash.mobile) {
      qs.device = 'mobile';
    }

    qs.autoStart = true;
    const disableAutoStartBackEnd = options && options.data && options.data.root
      && options.data.root.site && options.data.root.site.disableAutoStart;

    const disableAutoStartFrontEnd = global && global.bcGallery && global.bcGallery.filteredAssemblerData
      && global.bcGallery.filteredAssemblerData.site && global.bcGallery.filteredAssemblerData.site.disableAutoStart;

    if (disableAutoStartFrontEnd || disableAutoStartBackEnd) {
      delete qs.autoStart;
    }

    const site = options.data.root && options.data.root.site;
    if (site && (site.isMobile || site.isTablet)) {
      delete qs.autoStart; //Autoplay doesn't work on mobile or tablets, so we're loading preview image instead
    }

    const browser = global && global.videojs && global.videojs.browser;
    if (browser && (browser.IS_IOS || browser.IS_ANDROID)) {
      delete qs.autoStart; //Also disable mobile/tablet autoplay if the helper was called from the browser
    }

    if (options.data.root && options.data.root.query && options.data.root.query.page) {
      qs.page = options.data.root.query.page;
    }
    if (options['hash']['query'] && options['hash']['query']['q'] && options['hash']['query']['q'].length > 0) {
      qs.q = options['hash']['query']['q'];
    }
    if (options.data.root && options.data.root.query && options.data.root.query['sort_by']) {
      qs.sort_by = options.data.root.query['sort_by'];
    }

    const category = options['hash']['category'];
    const slug = utils.toSlug(video['name']);
    let videoPath = baseUrl + '/detail';
    if (category && !qs.q) {
      videoPath += '/videos/' + category['slug'];
    }
    videoPath += '/video/' + video.id;
    if (!options.data.root.site || !options.data.root.site.seo || !options.data.root.site.seo.disableVideoTitleInUrl) {
      videoPath += '/' + slug;
    }
    videoPath += utils.toQueryString(qs);

    if (options.hash['urlencode']) {
      videoPath = encodeURIComponent(videoPath);
    }

    // video_path helper is used in situations like carousels or modals in which case
    // it would not be appropriate to use the "shareUrl" helper as it would not represent
    // the actual intended share URL for the video as it is not the requested page
    // this allows template to perform similar logic as the "shareUrl" assembler
    if (options.hash['allowTruncateVideoName'] && videoPath.length > 1000) {
      if (category && !qs.q) {
        videoPath = baseUrl + '/detail/videos/' + category['slug'] + '/video/' + video['id'] + '/' + utils.toQueryString(qs);
      } else {
        videoPath = baseUrl + '/detail/video/' + video['id'] + '/' + utils.toQueryString(qs);
      }

      if (options.hash['urlencode']) {
        videoPath = encodeURIComponent(videoPath);
      }
    }
    return videoPath;
  });

  Handlebars.registerHelper('thumbnail', function (url, options) {
    // For screenshots, the url should be something like "small01.png", provided
    // by JSON data
    if (options.data.root.screenshot) {
      const templateId = options.data.root.template.id.replace(/_responsive/g, '');
      url = 'https://s3.amazonaws.com/constellation-test-east/photos/' +
        templateId + '/images/' + url;
    }

    // do we want protocol agnostic URLs to the trancoder
    let imageTranscoder = options.data.root.imageTranscoder;
    if (options && options.hash && options.hash.protocolAgnostic) {
      imageTranscoder = imageTranscoder.replace('http:', '');
      imageTranscoder = imageTranscoder.replace('https:', '');
    }

    return new Handlebars.SafeString(imageTranscoder + '?image=' + encodeURIComponent(url));
  });

  Handlebars.registerHelper('video_timestamp', function (video, options) {
    let timestamp;
    const duration = parseInt(video.length, 10);
    const isUpcomingEvent = duration === -1;
    if (isNaN(duration) || isUpcomingEvent) {
      return '';
    }
    const hours = Math.floor(duration / 1000 / 60 / 60);
    const minutes = Math.floor(duration / 1000 / 60 % 60);
    const seconds = utils.pad(Math.floor(duration / 1000 % 60), 2, '0');

    if (hours === 0 && minutes === 0) {
      timestamp = '0:' + seconds;
    } else if (hours === 0) {
      timestamp = minutes + ':' + seconds;
    } else {
      timestamp = hours + ':' + utils.pad(minutes, 2, '0') + ':' + seconds;
    }

    if (hours < 0 || minutes < 0 || seconds < 0) {
      const translations = options.data.root.translations || window.translations || {};
      //we have a live event
      timestamp = translations['LIVE'] || 'LIVE';
    }

    return timestamp;
  });

  Handlebars.registerHelper('hashed_link', function (url, options) {
    let route = '';
    if (options['hash']['category'] && options['hash']['category']['slug']) {
      route = 'category/videos/' + options['hash']['category']['slug'];
    }
    if (options['hash']['query'] && options['hash']['query']['q']) {
      route = 'search/' + encodeURIComponent(options['hash']['query']['q']);
    }

    const params = utils.getQueryParams(url);
    let page = '';
    if (params.page) {
      page = params.page;
    }

    const link = ['#', route, '/', page].join('');
    return link;
  });

  Handlebars.registerHelper('translate', function (key, context) {
    if (!context) {
      const message = 'Translate helper: context object undefined. Failed to translate ' + key;
      logger.error(message);
      return;
    }

    const phrase = (context.translations) ? context.translations[key] : context[key];
    if (!phrase) {
      logger.error('Translate helper: no phrase found for ' + key + ' in', context.translations);
      return '';
    }

    return new Handlebars.SafeString(Handlebars.compile(phrase)(context));
  });

  // Sanitizes output for JavaScript string contexts using backslash-encoding
  Handlebars.registerHelper('js', function (evil) {
    if (typeof evil === 'undefined') {
      return '';
    }
    return new Handlebars.SafeString(secureFilters.js(evil));
  });

  // Sanitizes output for a JavaScript literal in an HTML script context
  Handlebars.registerHelper('jsObj', function (evil) {
    //We should be returning null here, otherwise we end up with objects that have properties set to nothing
    //const site = {
    //  activePage: , //<-- Will throw an error
    //  foo: "bar"
    //};
    if (typeof evil === 'undefined') {
      return 'null';
    }
    return new Handlebars.SafeString(secureFilters.jsObj(evil));
  });

  Handlebars.registerHelper('filteredAssemblerData', function (root) {
    if (typeof root === 'undefined') {
      return 'null';
    }
    const dynamicCustom = root.site.dynamicCustom || {};
    let liveEventStatusURL;

    if (config.env === 'development') {
      liveEventStatusURL = root.isPreview ? `${config.sites.protocol}://${root.site.slug}.preview.${config.sites.hostname}:${config.sites.port}/api/liveeventdevfiles`
        : `${config.sites.protocol}://${root.site.slug}.${config.sites.hostname}:${config.sites.port}/api/liveeventdevfiles`;
    } else {
      liveEventStatusURL = `${config.liveStatus.endpointBaseUrl}`;
    }
    if (root.isPreview) {
      liveEventStatusURL += '/preview';
    }
    liveEventStatusURL += `/${root.account.id}/${root.site._id}.json`;

    const site = {
      id: root.site._id.toString(),
      name: root.site.name,
      slug: root.site.slug,
      agenda: root.site.agenda,
      sponsorGroups: root.site.sponsorGroups,
      search: {
        enabled: !!root.site.search.enabled,
      },
      sorting: {
        enabled: !!root.site.sorting.enabled,
      },
      activePage: root.site.activePage,
      indexPageReplace: dynamicCustom.indexPageReplace,
      share: root.site.share,
      redirect: root.site.redirect,
      isMobile: root.site.isMobile,
      autoplayNext: root.site.autoplayNext,
      disableAutoStart: root.site.disableAutoStart,
      relatedLinks: root.site.relatedLinks,
      subPath: root.subPath,
      isEdit: !!root.site.isEdit,
      useLongDescription: !!root.site.useLongDescription,
      useInlinePlaybackMobile: !!root.site.useInlinePlaybackMobile,
      useMultiLingualAudio: !!root.site.useMultiLingualAudio,
      dynamicCustom: {
        burgerMenu: dynamicCustom.burgerMenu,
        hideCarouselThumbnails: dynamicCustom.hideCarouselThumbnails,
        hideCarouselChevrons: dynamicCustom.hideCarouselChevrons,
        sidebarFooter: dynamicCustom.sidebarFooter,
        groupedFooter: dynamicCustom.groupedFooter,
        showFullVideoDescription: dynamicCustom.showFullVideoDescription,
        dynamicCategoryLoad: dynamicCustom.dynamicCategoryLoad,
        // 'crop' (default) | 'maintain' | 'stretch'
        liveEventBackgroundFit_index: dynamicCustom.liveEventBackgroundFit_index,
        'liveEventBackgroundFit_index-live': dynamicCustom['liveEventBackgroundFit_index-live'],
        'liveEventBackgroundFit_index-post': dynamicCustom['liveEventBackgroundFit_index-post'],
        liveEventStatusURL,
      },
      addons: root.site.addons,
      policyKey: root.site.policyKey,
      videoDownload: root.site.videoDownload,
      downloadProxyUrl: root.site.downloadProxyUrl.endpointBaseUrl,
      seo: root.site.seo,
    };

    const account = {
      bcAccountId: root.account.bcAccountId,
      playbackAuthEnabled: !!root.account.playbackAuth.publicKeyId,
      productType: root.site.productType,
    };

    const filteredAssemblerData = {
      site,
      imageTranscoder: root.imageTranscoder,
      breakoutRoomsWSAPI: root.breakoutRoomsWSAPI,
      baseUrl: root.baseUrl,
      playerBackground: root.playerBackground,
      isPreview: root.isPreview,
      locale: root.locale,
      dynamicCustom: root.dynamicCustom,
      subPath: root.subPath,
      query: root.query,
      player: root.player,
      mute: root.mute,
      templatePath: root.templatePath,
      videos: root.videos,
      singleVideo: root.singleVideo,
      video: root.video,
      videoLive: root.videoLive,
      category: root.category,
      categories: root.categories,
      translations: root.translations,
      samlAttributes: root.samlAttributes,
      sharedConstants: root.sharedConstants,
      account,
      sso: root.sso,
      ssoLoggedIn: root.session && root.session.sso,
      edgeApi: config.edgeApi.endpointBaseUrl,
    };

    return new Handlebars.SafeString(secureFilters.jsObj(filteredAssemblerData));
  });

  // Sanitizes output for embedded HTML scripting attributes
  Handlebars.registerHelper('jsAttr', function (evil) {
    if (typeof evil === 'undefined') {
      return '';
    }
    return secureFilters.jsAttr(evil);
  });

  // Sanitizes output in URI component contexts by using percent-encoding
  Handlebars.registerHelper('uri', function (evil) {
    if (typeof evil === 'undefined') {
      return '';
    }
    return secureFilters.uri(evil);
  });

  // Encodes values for safe embedding in HTML style attribute context.
  Handlebars.registerHelper('style', function (evil) {
    if (typeof evil === 'undefined') {
      return '';
    }
    return secureFilters.style(evil);
  });

  // Sanitizes output in CSS contexts by using backslash encoding
  Handlebars.registerHelper('css', function (evil) {
    if (typeof evil === 'undefined') {
      return '';
    }
    return secureFilters.css(evil);
  });

  Handlebars.registerHelper('debug', function (toInspect) {
    console.log('handlebars debug\n', toInspect);
  });

  Handlebars.registerHelper('categoryIdToName', function (parentId, allCategories) {
    if (!allCategories || !allCategories.length) {
      return '';
    }

    const parentCategory = _.findWhere(allCategories, {
      id: parentId,
    });

    if (!parentCategory) {
      return '';
    }

    return parentCategory.name;
  });

  // provides the logical basis for the "hasCta" and "unlessHasCta" helpers
  //
  // the assembler of the actual 'ctas' object handles the logic of creating
  // or excluding a cta spot based on its value and if the user is viewing from
  // within the WYSIWYG experience - it's only up to this helper to act as a
  // convenient way to check if the assembler produced a valid cta
  function hasCtaHelper (ctas, ctaId, options) {
    if (options && options.hash && options.hash.forIndex) {
      ctaId += '_' + options.hash.forIndex;
    }

    return ctas && ctas[ctaId];
  }

  Handlebars.registerHelper('hasCta', function (ctas, ctaId, options) {
    return hasCtaHelper(ctas, ctaId, options) ? options.fn(this) : options.inverse(this);
  });

  // convenience helper for "not" case of "hasCta"
  Handlebars.registerHelper('unlessHasCta', function (ctas, ctaId, options) {
    return hasCtaHelper(ctas, ctaId, options) ? options.inverse(this) : options.fn(this);
  });

  Handlebars.registerHelper('numberOfVideosOnNextPage', function (options) {
    const count = options.hash['count'];
    const totalCount = options.hash['totalCount'];
    const end = options.hash['end'] || count;

    if (totalCount - end < count) {
      return totalCount - end;
    } else if (totalCount === end) {
      return 0;
    } else {
      return count;
    }
  });

  // Takes a string and tokenizes it based on the specified delimiter
  Handlebars.registerHelper('each_string_token', function (tagString, delimiter, options) {
    let result = '';
    const tags = tagString.split(delimiter);

    for (let i = 0, len = tags.length; i < len; i++) {
      result += options.fn(tags[i].trim());
    }

    return result;
  });

  Handlebars.registerHelper('capitalizeFirstLetter', function (str) {
    if (str && str.length >= 1) {
      return str && str[0].toLocaleUpperCase() + str.slice(1);
    } else {
      return '';
    }
  });

  // determine if at least one share option exists
  Handlebars.registerHelper('hasShareOption', function (shareOptions, options) {
    let hasOption = false;

    // custom message options contain 'Msg' in their key name but
    // we're only checking for the main enabled options
    _.each(shareOptions, function (shareOption, key) {
      if (key.indexOf('Msg') < 0 && key.indexOf('Subj') < 0 && shareOption !== false) {
        hasOption = true;
      }
    });

    if (hasOption) {
      return options.fn(this);
    }

    return options.inverse(this);
  });

  Handlebars.registerHelper('containsString', function (string, match, options) {
    if (!string) {
      return '';
    }
    return (string.indexOf(match) > -1) ? options.fn(this) : '';
  });

  Handlebars.registerHelper('notContainsString', function (string, match, options) {
    if (!string) {
      return options.fn(this);
    }
    return (string.indexOf(match) < 0) ? options.fn(this) : '';
  });

  Handlebars.registerHelper('stringConcat', function () {
    const strings = Array.prototype.slice.call(arguments, 0, -1);
    return strings.join('');
  });

  // searches an object and makes sure each of the specified keys
  // are populated with a non-empty value
  // allows any number of
  //
  // if a "subKey" is specified in options.hash, the objects
  // at each of the originally specified keys
  // will be checked for a non-empty value
  //
  // passing "isEdit=true" on options.hash will make
  // this always behave "truthy"
  //
  // example usage:
  //
  // myObject = {
  //   "key-1": "value-1"
  //   "key-2": "value-2",
  //   "key-3": "value-3"
  // };
  //
  // {{#ifNonEmptyKeys myObject 'key-1', 'key-2', 'key-3'}}{{/ifNonEmptyKeys}}
  //
  // myObject2 = {
  //   "key-1": {
  //      "subkey": "subvalue-1"
  //   },
  //   "key-2": {
  //      "subkey": "subvalue-1"
  //   },
  //   "key-3": {
  //      "subkey": "subvalue-1"
  //   }
  // };
  //
  // {{#ifNonEmptyKeys myObject 'key-1', 'key-2', 'key-3' subKey='subkey'}}{{/ifNonEmptyKeys}}
  //
  //
  Handlebars.registerHelper('ifNonEmptyKeys', function () {
    const args = Array.prototype.slice.call(arguments);

    const options = args.pop();
    if (args.length === 0) {
      return options.inverse(this);
    } else if (options.hash && options.hash.isEdit) {
      return options.fn(this);
    }

    const content = args.shift();
    let hasContent = false;
    const subKey = options.hash && options.hash.subKey ? options.hash.subKey : null;
    _.each(args, function (key) {
      let test = content && content[key] ? content[key] : null;
      if (test && subKey) {
        test = test[subKey];
      }
      hasContent = hasContent || !_.isEmpty(test);
    });

    if (hasContent) {
      return options.fn(this);
    } else {
      return options.inverse(this);
    }
  });

  Handlebars.registerHelper('atdUserHasRole', function (user, video, options) {
    let canView = false;

    if (!video) {
      return options.inverse(this);
    }

    if (!video.customFields || !video.customFields.roles) {
      canView = true;
    } else {
      const userRoles = user && user.roles ? user.roles.split(',') : [];

      _.each(userRoles, function (role) {
        if (role === video.customFields.roles.toLowerCase()) {
          canView = true;
        }
      });
    }

    if (canView) {
      return options.fn(this);
    }

    return options.inverse(this);
  });

  const atdUserToRoleType = function (roles, forDisplay) {
    if (!roles) {
      return forDisplay ? '' : 'none';
    }

    // the following assumes that because roles are a comma separated string
    // we can use basic string matching to find the highest role available to the
    // user just by searching the string rather than inspecting each token
    // which also means that the order of matching will matter to make sure we return
    // the HIGHEST role available
    const userRoles = roles.toLowerCase();

    if (userRoles.indexOf('enterprise') >= 0) {
      return forDisplay ? 'Enterprise' : 'enterprise';
    } else if (userRoles.indexOf('astd_gg_members_plus') >= 0) {
      return forDisplay ? 'Member' : 'professional-plus';
    } else if (userRoles.indexOf('astd_gg') >= 0 || userRoles.indexOf('astd_cops') >= 0) {
      return forDisplay ? 'Member' : 'professional';
    } else if (userRoles.indexOf('astd_libraries') >= 0 || userRoles.indexOf('astd libraries') >= 0) {
      return forDisplay ? 'Conference Attendee' : 'conference';
    }

    // this is the catch-all if no other users were found
    return forDisplay ? 'Registered User' : 'registered-user';
  };

  Handlebars.registerHelper('atdUserToRoleType', function (user, options) {
    const userRoles = user && user.roles ? user.roles : null;
    const forDisplay = options && options.hash ? options.hash['forDisplay'] : false;
    return new Handlebars.SafeString(atdUserToRoleType(userRoles, forDisplay));
  });

  Handlebars.registerHelper('atdUserHasRoleType', function (user, roleType, options) {
    const foundRoleType = atdUserToRoleType(user ? user.roles : null);
    if (foundRoleType === roleType) {
      return options.fn(this);
    }
    return options.inverse(this);
  });

  Handlebars.registerHelper('atdLockedVideoMessage', function (user, video, options) {
    const asType = options && options.hash ? options.hash.asType : false;
    const userRoleType = atdUserToRoleType(user ? user.roles : null);
    const videoRoleType = atdUserToRoleType((video && video.customFields) ? video.customFields.roles : false);
    let lockedMessage = 'Please sign in as a member or enterprise customer to view this video.';
    let lockedMessageType = 'none';

    // shortcut for non-logged in users
    if (userRoleType === 'none' && videoRoleType !== 'conference') {
      return asType ? lockedMessageType : lockedMessage;
    }

    switch (videoRoleType) {
      case 'enterprise':
        lockedMessage = 'This is a Resource Center video. Please contact the Enterprise Solutions group at <span class="lock__popover-email-link" data-email="enterprise@td.org">enterprise@td.org</span> for more information about how your company can gain access.';
        lockedMessageType = 'enterprise';
        break;

      case 'professional':
      case 'professional-plus':
        if (userRoleType === 'registered-user' || userRoleType === 'conference') {
          lockedMessage = 'To view this video, become an ATD Member and opt in to this community.';
        } else {
          lockedMessage = 'This is a member video. To view you must opt in to this community.';
        }
        lockedMessageType = 'professional';
        break;

      case 'conference':
        lockedMessage = 'If you attended this ATD conference, please <span class="lock__popover-login-link">sign in</span> to view this video.';
        lockedMessageType = 'conference';
        break;
    }

    return asType ? lockedMessageType : new Handlebars.SafeString(lockedMessage);
  });

  // SHOWCASE TEMPLATE: determines if content header should be displayed
  Handlebars.registerHelper('ifShowcaseRequiresContentHeader', function (options) {
    const site = options && options.data && options.data.root ? options.data.root.site : null;

    let requiresHeader = false;
    requiresHeader = requiresHeader || (site && site.search && site.search.enabled);
    requiresHeader = requiresHeader || (site && site.activePage === 'search');

    if (requiresHeader) {
      return options.fn(this);
    }

    return options.inverse(this);
  });

  Handlebars.registerHelper('seoDescription', function (video, options) {
    const root = options && options.data && options.data.root || null;
    if (root && root.shareEventDetails && root.content.activePage === 'index') {
      const eventSocial = root.site && root.site.eventSocial;
      if (eventSocial && eventSocial.description) {
        return eventSocial.description;
      }
    }
    let site;
    let category;
    if (root) {
      site = root.site;
      category = root.category;
    }

    if (isHomePage(site) && site.useDescriptionInSEO) {
      return site.description;
    }
    if (isCategoryPage(site, category) && category.description) {
      return category.description;
    }

    return video && video.shortDescription || '';
  });

  Handlebars.registerHelper('seoImage', function (video, options) {
    const root = options && options.data && options.data.root || null;
    let site;
    let category;

    if (root) {
      site = root.site;
      category = root.category;
    }

    if (isHomePage(site) && site.image) {
      return site.image;
    }

    if (isCategoryPage(site, category) && category.image) {
      return category.image;
    }

    // default to video description as that is the original default behavior
    return video && video.galleryThumbnailURL || '';
  });

  // return description based on configuration in studio
  Handlebars.registerHelper('videoDescription', function (video, options) {
    if (!video) {
      return '';
    }
    const site = options && options.data && options.data.root ? options.data.root.site : null;
    let description;
    if (site && site.useLongDescription && video.longDescription && site.activePage === 'detail') {
      description = video.longDescription;
    } else {
      description = video.shortDescription;
    }
    return description || '';
  });

  // For landingpage, the video detail view is within the 'index' view
  // Due to this, the regular helper will not work as activePage is set to 'index' & long desc will never show
  // This is a workaround as changing the routing for landingpage will have SEO implications
  Handlebars.registerHelper('landingPageVideoDescription', function (video, options) {
    if (!video) {
      return '';
    }
    const site = options && options.data && options.data.root ? options.data.root.site : null;
    let description;
    if (site && site.useLongDescription && video.longDescription) {
      description = video.longDescription;
    } else {
      description = video.shortDescription;
    }
    return description || '';
  });

  // HERBALIFE TEMPLATE: determine if video has a "membertype1" or "private" custom field value specified
  function hlfVideoHasMemberType (video) {
    const hasCustomFields = video && video.customFields;
    const hasMemberType = hasCustomFields && !_.isEmpty(video.customFields.membertype1) && _.isString(video.customFields.membertype1) && video.customFields.membertype1 !== 'public';
    const hasPrivate = hasCustomFields && video.customFields.private === 'true';

    return hasMemberType || hasPrivate;
  }

  // HERBALIFE TEMPLATE: block helper around hlfVideoHasMemberType
  Handlebars.registerHelper('hlfVideoHasMemberType', function (video, options) {
    if (hlfVideoHasMemberType(video)) {
      return options.fn(this);
    }

    return options.inverse(this);
  });

  // HERBALIFE TEMPLATE: determine if video is watchable by user role if "membertype1" is set or by user presence if "private" is set
  // NOTE: similar logic in app.js for HLF template
  Handlebars.registerHelper('hlfUserCanWatch', function (user, video, options) {
    if (!hlfVideoHasMemberType(video)) {
      return options.fn(this);
    }

    // ASSUMPTION: existence of video.customFields and type of video.customFields.membertype1 confirmed by hlfVideoHasMemberType
    // check "membertype" first, force lowercase to match between SSO response and custom field values
    const memberType = !_.isEmpty(video.customFields.membertype1) ? video.customFields.membertype1.toLowerCase() : null;
    const memberRole = user && user.abbreviatedRole && _.isString(user.abbreviatedRole) ? user.abbreviatedRole.toLowerCase() : null;
    if (memberType) {
      if (memberType && memberRole && memberType.indexOf(memberRole) >= 0) {
        return options.fn(this);
      }
      return options.inverse(this);
    }

    // otherwise check "private", requires just a user to be present
    const isPrivate = !_.isEmpty(video.customFields.private) && video.customFields.private === 'true';
    if (isPrivate && user) {
      return options.fn(this);
    }

    // if anything else, user cannot watch
    return options.inverse(this);
  });

  // HERBALIFE TEMPLATE: provide translated, role-specific messaging for login copy
  Handlebars.registerHelper('hlfVideoLoginCopy', function (video, translations, memberType) {
    if (!video || !translations || !translations.loginCopy2 || !translations[memberType] || !memberType) {
      return '';
    }

    return tInjectFunction(translations.loginCopy2, {
      hash: {
        membertype: translations[memberType],
      },
    });
  });

  Handlebars.registerHelper('mk_get_share_url', function (videoId, options) {
    const root = options && options.data && options.data.root || null;
    const siteLanguage = root && root.locale && root.locale.language;
    let shareLandingPageUrl = 'http://marykay.gallery.video';

    if (siteLanguage && siteLanguage !== 'en-us' && siteLanguage !== 'es-us') {
      shareLandingPageUrl = 'https://marykaymultimedia.gallery.video';
    }

    if (siteLanguage && siteLanguage === 'en-hk' || siteLanguage === 'zh-hk' || siteLanguage === 'en-tw' || siteLanguage === 'zh-tw' || siteLanguage === 'en-ph' || siteLanguage === 'en-sg' || siteLanguage === 'zh-sg' || siteLanguage === 'en-my' || siteLanguage === 'zh-my' || siteLanguage === 'ms-my' ) {
      shareLandingPageUrl = 'https://multimedialibrary-apr.gallery.video';
    }

    if (siteLanguage && siteLanguage === 'pt-br' || siteLanguage === 'es-ar' || siteLanguage === 'es-uy' || siteLanguage === 'es-co' || siteLanguage === 'es-mx' || siteLanguage === 'es-pe') {
      shareLandingPageUrl = 'https://multimedialibrary-amr.gallery.video';
    }

    if (siteLanguage && siteLanguage === 'en-ca' || siteLanguage === 'fr-ca') {
      shareLandingPageUrl = 'https:/multimedialibrary-ca.gallery.video';
    }

    return shareLandingPageUrl + '?videoId=' + videoId;
  });

  Handlebars.registerHelper('mk_get_lang_code', function (language) {
    let languageCode = 'en';
    if (language !== 'pt-pt') {
      languageCode = language.split('-')[0];
    } else {
      languageCode = language;
    }
    return languageCode;
  });

  Handlebars.registerHelper('svg_path', function (name) {
    return constants.SVG_PATHS[name] || '';
  });

  // MOSAIC TEMPLATE SPECIFIC: provide <text> as the videos-found-for dictionary entry
  // Provide <resultsAmount> and <query> to replace text in the dictionary entry
  Handlebars.registerHelper('format_result_message', function (text, resultsAmount, query) {
    text = text.replace('{{amount}}', '<span id="bc-search-results-highlight">' + resultsAmount + '</span>');
    text = text.replace('{{searchTerm}}', '<span id="bc-search-results-highlight">' + query + '</span>');
    return text;
  });

  Handlebars.registerHelper('isLiveProcessing', function (status, options) {
    if (!status) {
      return options.inverse(this);
    }
    return status === 'processing' ? options.fn(this) : options.inverse(this);
  });

  Handlebars.registerHelper('isSocialConfigured', function (addons, social, media = {}) {
    if ( typeof (addons) === 'undefined' || typeof (addons.customTemplateJson) === 'undefined' ) {
      return media.hasOwnProperty(social) && media[social];
    }
    const customData = addons.customTemplateJson.json;
    if ( typeof (customData) === 'undefined' || customData === '' ) {
      return media.hasOwnProperty(social) && media[social];
    }
    const customJson = JSON.parse(customData);
    let hasSocial = false;
    if ( customJson.hasOwnProperty('supported_social_shares')) {
      const customSocials = customJson.supported_social_shares;
      hasSocial = customSocials.includes(social);
    } else {
      hasSocial = media.hasOwnProperty(social) && media[social];
    }
    return hasSocial;
  });

  Handlebars.registerHelper('getSortOrder', function (addons, social) {
    let sortOrder = 0;
    if ( typeof (addons) === 'undefined' || typeof (addons.customTemplateJson) === 'undefined') {
      return sortOrder;
    }
    const customData = addons.customTemplateJson.json;
    if ( typeof (customData) === 'undefined' || customData === '' ) {
      return sortOrder;
    }
    const customJson = JSON.parse(customData);
    if ( customJson.hasOwnProperty('supported_social_shares')) {
      const customSocials = customJson.supported_social_shares;
      sortOrder = customSocials.indexOf(social);
    } else {
      sortOrder = 0;
    }
    return sortOrder.toString();
  });

  Handlebars.registerHelper('isPreEvent', function (status, options) {
    if (!status) {
      return options.inverse(this);
    }
    return status !== 'finished' && status !== 'processing' ? options.fn(this) : options.inverse(this);
  });

  Handlebars.registerHelper('shouldIncludeCustomFont', function (customFontUploaded, variant, site, options) {
    const fontFamilyVal = `custom${variant[0].toLocaleUpperCase() + variant.slice(1)}Font`;
    const fontFamilyKey = `font-family-${variant}`;

    const hasCustomFontUploaded = _.isString(customFontUploaded) && customFontUploaded.length > 0;
    const hasSelectedCustomFont = site.style !== undefined && site.style[fontFamilyKey] !== undefined && site.style[fontFamilyKey] === fontFamilyVal;

    if (hasCustomFontUploaded && hasSelectedCustomFont) {
      return options.fn(this);
    }
    return options.inverse();
  });
};

